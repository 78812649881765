export const types = {
  SET_REDIRECT: 'SET_REDIRECT',
  SET_AUTO_DONATION_APPLIED: 'SET_AUTO_DONATION_APPLIED',
  SET_AUTO_DONATION_REFUSED: 'SET_AUTO_DONATION_REFUSED',
  SET_AUTO_DONATION_BASKET_ID: 'SET_AUTO_DONATION_BASKET_ID',
  SET_SEAT_MAP_INSTRUCTIONS: 'SET_SEAT_MAP_INSTRUCTIONS',
  SET_SHOW_COOKIE_POLICY_BANNER: 'SET_SHOW_COOKIE_POLICY_BANNER',
  SET_COOKIE_PREFERENCES: 'SET_COOKIE_PREFERENCES',
  SET_COOKIE_CONSENT: 'SET_COOKIE_CONSENT',
}
