









































import { Component, Watch } from 'nuxt-property-decorator'
import MenuDesktop from '@Core/components/parts/header/menu/MenuDesktop.vue'
import { CmsMenuItem } from '@Core/@types/skyway'
import ArrowLeftSvg from '~/static/images/icons/arrow-left.svg?inline'

@Component({
  components: {
    MenuDesktop,
    ArrowLeftSvg,
  },
})
export default class MenuMobile extends MenuDesktop {
  @Watch('isOpen', { immediate: false })
  onIsOpenChange(newVal: boolean) {
    if (!newVal) {
      this.goBack()
    }
  }

  get showSubMenu() {
    return this.activeItem && this.activeItemHasChildren
  }

  get activeItemHasChildren(): Boolean {
    if (this.activeItem) {
      return this.activeItem.children.length > 0
    } else {
      return false
    }
  }

  goBack(): void {
    this.activeItem = null
    this.$refs.menu.classList.add('mobile-menu--hide-submenu')
    this.$refs.menu.classList.remove('mobile-menu--show-submenu')
  }

  setActiveItem(item: CmsMenuItem | null): void {
    this.activeItem = item
    this.$refs.menu.classList.add('mobile-menu--show-submenu')
    this.$refs.menu.classList.remove('mobile-menu--hide-submenu')
  }
}
