








































































import { Component, Prop, Vue, Watch, namespace } from 'nuxt-property-decorator'
import CloseSvg from '@icons/material/svg/close.svg?inline'
import MobileMenu from '@Core/components/parts/header/MobileMenu.vue'
import Breadcrumbs from '@UI/components/breadcrumbs/Breadcrumbs.vue'
import Hamburger from '@Core/components/parts/header/Hamburger.vue'
import SearchForm from '@UI/components/search-form/SearchForm.vue'
import { CmsMenu } from '@Core/@types/skyway'
import { Route } from 'vue-router'
import _throttle from 'lodash/throttle'
import _debounce from 'lodash/debounce'
import HeaderNav from '@Core/components/parts/header/menu/HeaderNav.vue'
import MagnifySvg from '@icons/material/svg/magnify.svg?inline'
import AccountSvg from '~/static/images/icons/person.svg?inline'
import BasketSvg from '~/static/images/icons/shopping_bag.svg?inline'
import LogoSvg from '~/static/images/logos/RedLogo.svg?inline'
import OffWhiteMastheadSvg from '~/static/images/logos/OffWhiteMasthead.svg?inline'
import CharitySvg from '~/static/images/svgs/donate.svg?inline'

const menu = namespace('menu')
const basket = namespace('basket')

@Component({
  components: {
    HeaderNav,
    MagnifySvg,
    LogoSvg,
    OffWhiteMastheadSvg,
    AccountSvg,
    BasketSvg,
    CharitySvg,
    Hamburger,
    MobileMenu,
    CloseSvg,
    Breadcrumbs,
    SearchForm,
  },
})
export default class HeaderPart extends Vue {
  @Prop({ type: Array, default: () => [] }) breadcrumbs!: {
    title: string
    href: Route
  }[]

  @Prop({ type: Boolean, default: false }) isRoundel!: Boolean
  @Prop({ type: Boolean, default: false }) isFixed!: Boolean
  @Prop({ type: Boolean, default: false }) hasInPageNav!: Boolean

  @menu.Action
  protected getMenu!: (slug: string) => Promise<CmsMenu>

  @menu.Getter
  protected menuBySlug!: (slug: string) => CmsMenu

  @basket.Getter
  protected basketIsEmpty!: Boolean

  protected menuIsOpen: Boolean = false

  protected activeItem: string | null = null

  protected scrollDirection: string | null = null
  protected startY: number = 0
  protected handleDebouncedScroll: any = null
  protected scrollDifference: number = 0
  protected modifier: string = ''

  protected showMainHeader = true
  protected showMiniHeader = false

  @Watch('$route.path')
  onRouteChange() {
    this.menuIsOpen = false
  }

  async fetch() {
    if (!this.menuBySlug('main-nav')) {
      await this.getMenu('main-nav')
    }
  }

  get logo(): string {
    return this.isRoundel ? 'LogoSvg' : 'OffWhiteMastheadSvg'
  }

  get mainMenu(): CmsMenu {
    return this.menuBySlug('main-nav')
  }

  get menuItems() {
    return this.mainMenu ? this.mainMenu.items : []
  }

  onMenuMouseover(id: string): void {
    this.activeItem = id
  }

  onMenuMouseleave(): void {
    this.activeItem = null
  }

  toggleMenu(): void {
    this.menuIsOpen = !this.menuIsOpen
  }

  handleCloseMenu(): void {
    this.menuIsOpen = false
  }
}
