// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loading{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}.error-component{margin:0 auto;display:flex;flex-direction:column;justify-content:flex-start;align-items:center;max-width:768px;padding:8em 1.25rem 0;min-height:100vh;text-align:center}.error-component h1{font-size:4.375rem;line-height:80%;font-weight:700}@media only screen and (min-width:1024px){.error-component h1{font-size:8.9375rem;line-height:80%}}.error-component h2{font-size:24px;line-height:32px;margin-bottom:.5rem}@media only screen and (min-width:1024px){.error-component h2{font-size:32px;line-height:48px}}.error-component h2 span{display:block}@media only screen and (min-width:1024px){.error-component h2 span{display:inline-block}}.error-component h3,.error-component p{line-height:1.5rem;font-size:1.125rem;color:#b7b7b8;font-weight:400;font-family:\"Work Sans\",sans-serif;margin:0 0 .5rem}.error-component h3.secondary-info,.error-component p.secondary-info{font-weight:400;font-size:.8125rem;line-height:1rem}.error-component .button-wrap{display:flex}.error-component .button{display:inline-block;width:auto;margin:.5em .5em 3em}.error-component .button--primary{color:#e0e0e0}@media only screen and (min-width:1024px){.error-component h1.basket-empty__header{font-size:5rem;line-height:5.625rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#000",
	"blackBg": "#121212",
	"white": "#fff",
	"offWhite": "#f7f6f3",
	"grey": "#b7b7b8",
	"darkGrey": "#474747",
	"mediumGrey": "#7d7d7d",
	"lightGrey": "#e0e0e0",
	"darkGrey60": "rgba(66,66,66,.6)",
	"lightGrey60": "hsla(0,0%,87.8%,.6)",
	"red": "#db0000",
	"violet": "#b8a4ea",
	"lime": "#d4fe01",
	"price1": "#e6ff66",
	"price2": "#b699ff",
	"price3": "#66ff91",
	"price4": "#f68383",
	"price5": "#80a4ff",
	"price6": "#ff99c0",
	"price7": "#00b893",
	"price8": "#ff9166",
	"screen375": "375px",
	"screen480": "480px",
	"screen640": "640px",
	"screen768": "768px",
	"screen1024": "1024px",
	"screen1200": "1200px",
	"screen1440": "1440px",
	"screen1560": "1560px"
};
module.exports = ___CSS_LOADER_EXPORT___;
