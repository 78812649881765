




















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import MobileMenuMain from '@Core/components/parts/header/MobileMenuMain.vue'
import MobileMenuSub from '@Core/components/parts/header/MobileMenuSub.vue'
import { CmsMenuItem } from '@Core/@types/skyway'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

@Component({
  components: {
    MobileMenuMain,
    MobileMenuSub,
  },
})
export default class MobileMenu extends Vue {
  @Prop({ type: Boolean, default: false }) isOpen!: boolean
  @Prop({ type: Array, required: true }) items!: CmsMenuItem[]

  private menuToShow: string = 'main'

  get renderMenuComponent(): string {
    return this.menuToShow === 'main' ? 'MobileMenuMain' : 'MobileMenuSub'
  }

  get linksToShow(): CmsMenuItem[] {
    if (this.menuToShow === 'main') {
      return this.items
    } else {
      const menuItem: any = this.items.find(
        ({ name }) => name && name.toLowerCase() === this.menuToShow
      )
      return [menuItem, ...menuItem!.children]
    }
  }

  openMenu(item): void {
    if (typeof item === 'string') {
      this.menuToShow = item
      if (this.$refs.wrapper) {
        this.$refs.wrapper.style.transform = 'translateX(-100%)'
      }
    } else {
      this.$router.push(`/${item.href}`)
    }
  }

  returnToMainMenu(): void {
    if (this.$refs.wrapper) {
      this.$refs.wrapper.style.transform = 'translateX(0%)'
    }
  }

  mounted(): void {
    disableBodyScroll(this.$refs.mobileMenu)
  }

  beforeDestroy(): void {
    clearAllBodyScrollLocks()
  }
}
