


























import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
// @ts-ignore:next-line
import ArrowBackSvg from '@icons/material/svg/arrow-left.svg?inline'
import { CmsMenuItem } from '@Core/@types/skyway'

@Component({
  components: {
    ArrowBackSvg,
  },
})
export default class MobileMenuSub extends Vue {
  @Prop({ type: Array, required: true }) links!: CmsMenuItem[]
  @Prop({ type: String, required: true }) showing!: string

  @Emit()
  openMenu(item) {
    return item
  }

  @Emit('returnToMainMenu')
  returnToMainMenu() {
    return 'returnToMainMenu'
  }

  getLinkComponent(link: string) {
    return link.includes('http') ? 'a' : 'nuxt-link'
  }
}
