











































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { CmsMenuItem } from '@Core/@types/skyway'
import _throttle from 'lodash/throttle'
import MenuDesktop from '@Core/components/parts/header/menu/MenuDesktop.vue'
import MenuMobile from '@Core/components/parts/header/menu/MenuMobile.vue'
import SearchForm from '@UI/components/search-form/SearchForm.vue'
import LogoSvg from '~/static/images/logos/RedLogo.svg?inline'
import MastheadLogoSvg from '~/static/images/logos/OffWhiteMasthead.svg?inline'
import AccountSvg from '~/static/images/icons/person.svg?inline'
import BasketSvg from '~/static/images/icons/shopping_bag.svg?inline'
import CloseSvg from '~/static/images/icons/close.svg?inline'

@Component({
  components: {
    MenuDesktop,
    MenuMobile,
    LogoSvg,
    MastheadLogoSvg,
    AccountSvg,
    BasketSvg,
    CloseSvg,
    SearchForm,
  },
})
export default class HeaderNav extends Vue {
  $refs!: {
    mainNav: HTMLDivElement
  }

  @Prop({ type: Array, required: true }) items!: CmsMenuItem[]
  @Prop({ type: Boolean, default: false }) isOpen!: boolean
  @Prop({ type: Boolean }) basketIsEmpty!: boolean

  protected searchterm: string = ''
  protected activeItem: string | null = null
  protected submenuHeight: string | undefined

  removeOpeningClass() {
    this.$refs.mainNav.classList.remove('main-nav--opening')
  }

  removeClosingClass() {
    this.$refs.mainNav.classList.remove('main-nav--closing')
    this.$refs.mainNav.classList.remove('main-nav--close')
  }

  @Watch('isOpen', { immediate: false })
  onIsOpenChange(newVal: boolean): void {
    const mainNav = this.$refs.mainNav
    if (newVal && mainNav) {
      mainNav.classList.add('main-nav--open')
      mainNav.classList.add('main-nav--opening')
      mainNav.classList.remove('main-nav--close')
      mainNav.addEventListener('animationend', this.removeOpeningClass)
    } else if (mainNav) {
      mainNav.classList.add('main-nav--close')
      mainNav.classList.add('main-nav--closing')
      mainNav.classList.remove('main-nav--open')
      mainNav.addEventListener('animationend', this.removeClosingClass)
    }
  }

  @Watch('$route.path')
  onRouteChange(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.activeItem = null
    }
  }

  @Emit('closeMenu')
  closeMenu(): string {
    return 'closeMenu'
  }
}
