import { MutationTree } from 'vuex'
import { State } from './state'
import { types } from './types'

const mutations: MutationTree<State> = {
  [types.SET_REDIRECT](state: State, payload: string): void {
    state.redirect = payload
  },
  [types.SET_AUTO_DONATION_APPLIED](state: State, payload: boolean): void {
    state.autoDonationApplied = payload
  },
  [types.SET_AUTO_DONATION_REFUSED](state: State, payload: boolean): void {
    state.autoDonationRefused = payload
  },
  [types.SET_AUTO_DONATION_BASKET_ID](state: State, payload: number): void {
    state.autoDonationBasketId = payload
  },
  [types.SET_SEAT_MAP_INSTRUCTIONS](state: State, payload: boolean): void {
    state.seatMapInstructionsDismissed = payload
  },
  [types.SET_COOKIE_CONSENT](state: State, payload: boolean): void {
    state.cookieConsent = payload
  },
  [types.SET_COOKIE_PREFERENCES](state: State, payload: string[]): void {
    state.cookiePreferences = payload
  },
  [types.SET_SHOW_COOKIE_POLICY_BANNER](state: State, payload: boolean): void {
    state.showCookiePolicyBanner = payload
  },
}

export default mutations
