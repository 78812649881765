import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import { State } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  setRedirect(
    context: ActionContext<State, RootState>,
    redirect: string
  ): Promise<boolean> {
    return new Promise((resolve) => {
      context.commit(types.SET_REDIRECT, redirect)
      resolve(true)
    })
  },

  getRedirect(context: ActionContext<State, RootState>): Promise<any> {
    return new Promise((resolve) => {
      resolve(context.state.redirect)
    })
  },

  getAutoDonationBasketId(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    return new Promise((resolve) => {
      resolve(context.state.autoDonationBasketId)
    })
  },

  setCookieConsent(
    context: ActionContext<State, RootState>,
    payload: boolean
  ): void {
    context.commit(types.SET_COOKIE_CONSENT, payload)

    if (payload) {
      context.commit(types.SET_SHOW_COOKIE_POLICY_BANNER, false)
    }
  },

  setCookiePreferences(
    context: ActionContext<State, RootState>,
    payload: boolean
  ): void {
    context.commit(types.SET_COOKIE_PREFERENCES, payload)
  },
}

export default actions
