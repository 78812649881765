export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** Sanitised string, which can be used as ID */
  OID: any
  /** Date custom scalar type */
  Date: any
  JSON: any
}

export type AccountInput = {
  account_name?: Maybe<Scalars['String']>
  account_number?: Maybe<Scalars['String']>
  account_type_ref?: Maybe<Scalars['OID']>
}

export type ActivateAccountInput = {
  customer_ref?: Maybe<Scalars['OID']>
  last_name: Scalars['String']
  credentials: LoginInput
}

export type AddContributionInput = {
  fund_ref: Scalars['OID']
  amount: Scalars['Float']
  on_account?: Maybe<Scalars['Boolean']>
  note?: Maybe<Scalars['String']>
}

export type AddFixedPackageInput = {
  package_ref: Scalars['OID']
  quantity: Scalars['Int']
  price_type_ref: Array<Maybe<Scalars['OID']>>
  seats?: Maybe<Array<Maybe<Scalars['OID']>>>
  mode_of_sale_ref?: Maybe<Scalars['OID']>
  zone_ref?: Maybe<Array<Maybe<Scalars['OID']>>>
  extra?: Maybe<Scalars['JSON']>
}

export type AddFlexPackageInput = {
  package_ref: Scalars['OID']
  mode_of_sale_ref?: Maybe<Scalars['OID']>
  instances?: Maybe<Array<Maybe<AddFlexPackageInstanceInput>>>
  unseated?: Maybe<Scalars['Boolean']>
  extra?: Maybe<Scalars['JSON']>
}

export type AddFlexPackageInstanceInput = {
  instance_ref: Scalars['OID']
  zone_ref?: Maybe<Scalars['OID']>
  quantity: Scalars['Int']
  group_ref: Scalars['OID']
  price_type_ref: Array<Maybe<Scalars['OID']>>
  seats?: Maybe<Array<Maybe<Scalars['OID']>>>
}

export type AddGiftCertificateInput = {
  amount?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  from_name?: Maybe<Scalars['String']>
  delivery_type?: Maybe<Scalars['OID']>
  delivery_email?: Maybe<Scalars['String']>
}

export type AddMembershipInput = {
  fund_ref?: Maybe<Scalars['OID']>
  amount?: Maybe<Scalars['Float']>
  level_ref: Scalars['OID']
  upgrade?: Maybe<Scalars['Boolean']>
  renew?: Maybe<Scalars['Boolean']>
  on_account?: Maybe<Scalars['Boolean']>
  auto_renew?: Maybe<Scalars['Boolean']>
  note?: Maybe<Scalars['String']>
}

export type AddSpecialRequestInput = {
  line_item_ref?: Maybe<Scalars['OID']>
  aisle_seat?: Maybe<Scalars['String']>
  contiguous_seats?: Maybe<Scalars['Int']>
  ending_row?: Maybe<Scalars['String']>
  ending_seat?: Maybe<Scalars['String']>
  starting_row?: Maybe<Scalars['String']>
  starting_seat?: Maybe<Scalars['String']>
  end_price?: Maybe<Scalars['Float']>
  start_price?: Maybe<Scalars['Float']>
  hold_code?: Maybe<Scalars['Int']>
  leaving_single_seats?: Maybe<Scalars['Boolean']>
  no_stairs?: Maybe<Scalars['Boolean']>
  wheelchair_seats?: Maybe<Scalars['Int']>
  notes?: Maybe<Scalars['String']>
}

export type AddTicketInput = {
  instance_ref: Scalars['OID']
  seats?: Maybe<Array<Maybe<Scalars['OID']>>>
  qty: Scalars['Int']
  extra?: Maybe<Scalars['JSON']>
}

export type AddTicketsInput = {
  tickets?: Maybe<Array<Maybe<AddTicketInput>>>
}

export type Affiliation = {
  affiliation_ref?: Maybe<Scalars['OID']>
  household_ref?: Maybe<Scalars['OID']>
  individual_ref?: Maybe<Scalars['OID']>
  type_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  household_name?: Maybe<Scalars['String']>
  individual_name?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['Date']>
  end_date?: Maybe<Scalars['Date']>
  updated_at?: Maybe<Scalars['Date']>
  active?: Maybe<Scalars['Boolean']>
  primary?: Maybe<Scalars['Boolean']>
  name_indicator?: Maybe<Scalars['Int']>
}

export type AffiliationInput = {
  affiliation_ref?: Maybe<Scalars['OID']>
  type_ref?: Maybe<Scalars['OID']>
  household_ref?: Maybe<Scalars['OID']>
  household_name?: Maybe<Scalars['String']>
  individual_ref?: Maybe<Scalars['OID']>
  individual_name?: Maybe<Scalars['String']>
  allowed_to_transact?: Maybe<Scalars['Boolean']>
  included_in_search?: Maybe<Scalars['Boolean']>
  start_date?: Maybe<Scalars['Date']>
  end_date?: Maybe<Scalars['Date']>
  primary?: Maybe<Scalars['Boolean']>
  name_indicator?: Maybe<Scalars['Int']>
  note?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['Date']>
}

export type AffiliationType = {
  affiliation_type_ref?: Maybe<Scalars['OID']>
  description?: Maybe<Scalars['String']>
}

export type ApplyCreditToBasketInput = {
  amount?: Maybe<Scalars['Float']>
  payment_method_ref?: Maybe<Scalars['OID']>
}

export type ApplyGiftMembershipInput = {
  gc_no: Scalars['String']
  level_ref: Scalars['OID']
  amount?: Maybe<Scalars['Float']>
}

export type Artist = {
  id?: Maybe<Scalars['OID']>
  firstname?: Maybe<Scalars['String']>
  lastname?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  professional_title?: Maybe<Scalars['String']>
  profile?: Maybe<Scalars['String']>
  group?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
  type?: Maybe<ArtistType>
}

export type ArtistType = {
  id?: Maybe<Scalars['OID']>
  role?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type Association = {
  association_ref?: Maybe<Scalars['OID']>
  reciprocal_type_ref?: Maybe<Scalars['OID']>
  associated_customer_ref?: Maybe<Scalars['OID']>
  type_ref?: Maybe<Scalars['OID']>
  customer_ref?: Maybe<Scalars['OID']>
  associated_customer_name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['Date']>
  start_date?: Maybe<Scalars['Date']>
  end_date?: Maybe<Scalars['Date']>
  note?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  primary?: Maybe<Scalars['Boolean']>
}

export type AssociationInput = {
  association_ref?: Maybe<Scalars['OID']>
  type_ref: Scalars['OID']
  reciprocal_type_ref?: Maybe<Scalars['OID']>
  associated_customer_ref?: Maybe<Scalars['OID']>
  associated_customer_name: Scalars['String']
  customer_ref: Scalars['OID']
  start_date?: Maybe<Scalars['Date']>
  end_date?: Maybe<Scalars['Date']>
  active?: Maybe<Scalars['Boolean']>
  note?: Maybe<Scalars['String']>
  primary?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['Date']>
}

export type AssociationType = {
  association_type_ref?: Maybe<Scalars['OID']>
  description?: Maybe<Scalars['String']>
}

export type AttributeType = {
  attribute_type_ref?: Maybe<Scalars['OID']>
  description?: Maybe<Scalars['String']>
}

export type Availability = {
  availability?: Maybe<Scalars['Int']>
  zone?: Maybe<Scalars['String']>
  zone_ref?: Maybe<Scalars['OID']>
}

export type Basket = {
  order_ref: Scalars['OID']
  customer_ref?: Maybe<Scalars['OID']>
  date: Scalars['Date']
  sub_total?: Maybe<Scalars['Float']>
  total: Scalars['Float']
  total_paid?: Maybe<Scalars['Float']>
  total_fees?: Maybe<Scalars['Float']>
  notes?: Maybe<Scalars['String']>
  expiry?: Maybe<Scalars['Date']>
  customer?: Maybe<Customer>
  groups?: Maybe<Array<Maybe<OrderGroup>>>
  product_fees?: Maybe<Array<Maybe<Fee>>>
  order_fees?: Maybe<Array<Maybe<Fee>>>
  payments?: Maybe<Array<Maybe<Payment>>>
  loyalty_points_redeemable?: Maybe<Scalars['Int']>
  loyalty_points_earned?: Maybe<Scalars['Int']>
}

export type BasketProperties = {
  mode_of_sale_ref?: Maybe<Scalars['OID']>
  source_ref?: Maybe<Scalars['OID']>
  hold_until_date_time?: Maybe<Scalars['Date']>
  notes?: Maybe<Scalars['String']>
  initiator_ref?: Maybe<Scalars['OID']>
  address_ref?: Maybe<Scalars['OID']>
  email_ref?: Maybe<Scalars['OID']>
  delivery_method_ref?: Maybe<Scalars['OID']>
  order_date_time?: Maybe<Scalars['Date']>
}

export type BasketPropertiesInput = {
  mode_of_sale_ref?: Maybe<Scalars['OID']>
  source_ref?: Maybe<Scalars['OID']>
  hold_until_date_time?: Maybe<Scalars['Date']>
  notes?: Maybe<Scalars['String']>
  initiator_ref?: Maybe<Scalars['OID']>
  address_ref?: Maybe<Scalars['OID']>
  email_ref?: Maybe<Scalars['OID']>
  delivery_method_ref?: Maybe<Scalars['OID']>
  order_date_time?: Maybe<Scalars['Date']>
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type CalendarInput = {
  year: Scalars['Int']
  month: Scalars['Int']
  day?: Maybe<Scalars['Int']>
}

export type CmsAccordion = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  sections?: Maybe<Array<Maybe<CmsAccordionSection>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsAccordionSection = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsAnchorLink = {
  title?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsArchive = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  pagination?: Maybe<CmsArchivePagination>
  entries?: Maybe<Array<Maybe<CmsPostSummary>>>
  entries_extended?: Maybe<Array<Maybe<CmsPage>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsArchiveInput = {
  page?: Maybe<Scalars['Int']>
  per_page?: Maybe<Scalars['Int']>
  post_parent?: Maybe<Scalars['Int']>
  categories?: Maybe<Scalars['String']>
  tags?: Maybe<Scalars['String']>
  exclude?: Maybe<Scalars['String']>
  include?: Maybe<Scalars['String']>
  all_data?: Maybe<Scalars['Boolean']>
  meta_value?: Maybe<Scalars['String']>
  meta_key?: Maybe<Scalars['String']>
  order?: Maybe<OrderEnum>
  order_by?: Maybe<Scalars['String']>
  tax_queries?: Maybe<Array<Maybe<CmsTaxQueryInput>>>
}

export type CmsArchivePagination = {
  current_page?: Maybe<Scalars['Int']>
  total_entries?: Maybe<Scalars['Int']>
  entries_per_page?: Maybe<Scalars['Int']>
}

export type CmsArchiveParams = {
  type: Scalars['String']
  params?: Maybe<CmsArchiveInput>
}

export type CmsBanner = {
  type?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  image?: Maybe<CmsImage>
  svg?: Maybe<CmsImage>
  video?: Maybe<Scalars['String']>
  link?: Maybe<CmsLink>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsBreadcrumb = {
  name?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
}

export type CmsCategory = {
  id?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
}

export type CmsComponent = {
  name?: Maybe<Scalars['String']>
  data?: Maybe<Scalars['JSON']>
}

export type CmsContactForm = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  recipients?: Maybe<Array<Maybe<CmsContactFormRecipient>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsContactFormRecipient = {
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type Cmscta = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  link?: Maybe<CmsLink>
  image?: Maybe<CmsImage>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsctaCollection = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  ctas?: Maybe<Array<Maybe<Cmscta>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsCustomTaxonomy = {
  id?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
}

export type CmsCustomTaxonomyCollection = {
  type?: Maybe<Scalars['String']>
  taxonomies?: Maybe<Array<Maybe<CmsCustomTaxonomy>>>
}

export type CmsDonate = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  button_text?: Maybe<Scalars['String']>
  fund_id?: Maybe<Scalars['OID']>
  values?: Maybe<Array<Maybe<Scalars['String']>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsDonateFundSelection = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  manual?: Maybe<Scalars['Boolean']>
  funds?: Maybe<Array<Maybe<Fund>>>
}

export type CmsDownload = {
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  alt?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsDownloadCollection = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  files?: Maybe<Array<Maybe<CmsDownload>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsEmailInput = {
  recipient_email?: Maybe<Scalars['String']>
  sender_email?: Maybe<Scalars['String']>
  sender_name?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  use_html?: Maybe<Scalars['Boolean']>
}

export type CmsEmailResponse = {
  status?: Maybe<Scalars['Int']>
  message?: Maybe<Scalars['String']>
}

export type Cmsfaq = {
  question?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsfaqCategory = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  link?: Maybe<CmsLink>
  faqs?: Maybe<Array<Maybe<Cmsfaq>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsfaqCollection = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Maybe<CmsfaqCategory>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsFeaturedEventCollection = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  events?: Maybe<Array<Maybe<Event>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsFeaturedImage = {
  url?: Maybe<Scalars['String']>
  alt?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  mime_type?: Maybe<Scalars['String']>
  sizes?: Maybe<Array<Maybe<CmsImageSize>>>
}

export type CmsFeaturedPostCollection = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  posts?: Maybe<Array<Maybe<CmsPostSummary>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsFeaturedSearchQueryCollection = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  queries?: Maybe<Array<Maybe<CmsLink>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsFooterContent = {
  content?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsFullWIdthImage = {
  title?: Maybe<Scalars['String']>
  alt?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  mime_type?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  sizes?: Maybe<Array<Maybe<CmsImageSize>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsGallery = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  images?: Maybe<Array<Maybe<CmsImage>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsImage = {
  title?: Maybe<Scalars['String']>
  alt?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  mime_type?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  sizes?: Maybe<Array<Maybe<CmsImageSize>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsImageSize = {
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Int']>
  width?: Maybe<Scalars['Int']>
}

export type CmsInPageNav = {
  show_nav?: Maybe<Scalars['Boolean']>
}

export type CmsLayout = {
  name?: Maybe<Scalars['String']>
  components?: Maybe<Array<Maybe<CmsComponent>>>
}

export type CmsLink = {
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  target?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsListItem = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsListItemCollection = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  items?: Maybe<Array<Maybe<CmsListItem>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsMap = {
  title?: Maybe<Scalars['String']>
  marker?: Maybe<CmsMapMarker>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsMapMarker = {
  position?: Maybe<CmsMapPosition>
  address_1?: Maybe<Scalars['String']>
  address_2?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  postcode?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsMapPosition = {
  latitude?: Maybe<Scalars['String']>
  longitude?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsMembership = {
  level_ref?: Maybe<Scalars['OID']>
  membership?: Maybe<Membership>
}

export type CmsMenu = {
  id?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  items?: Maybe<Array<Maybe<CmsMenuItem>>>
}

export type CmsMenuItem = {
  id?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  target_type?: Maybe<Scalars['String']>
  children?: Maybe<Array<Maybe<CmsMenuItem>>>
}

export type CmsNestedLayout = {
  type?: Maybe<Scalars['String']>
  layout?: Maybe<CmsLayout>
}

export type CmsNotificationBanner = {
  content?: Maybe<Scalars['String']>
  link?: Maybe<CmsLink>
  active?: Maybe<Scalars['Boolean']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsoEmbed = {
  type?: Maybe<Scalars['String']>
  embed_code?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsOpeningHours = {
  title?: Maybe<Scalars['String']>
  days?: Maybe<Array<Maybe<CmsOpeningHoursDay>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsOpeningHoursDay = {
  day?: Maybe<Scalars['String']>
  hours?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsPage = {
  id?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  featured_image?: Maybe<CmsFeaturedImage>
  datetime?: Maybe<Scalars['Date']>
  author?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  template?: Maybe<Scalars['String']>
  tags?: Maybe<Array<Maybe<CmsTag>>>
  categories?: Maybe<Array<Maybe<CmsCategory>>>
  taxonomies?: Maybe<Array<Maybe<CmsCustomTaxonomyCollection>>>
  breadcrumbs?: Maybe<Array<Maybe<CmsBreadcrumb>>>
  previous?: Maybe<CmsPageLink>
  next?: Maybe<CmsPageLink>
  layouts?: Maybe<Array<Maybe<CmsLayout>>>
  parent?: Maybe<CmsPage>
  siblings?: Maybe<Array<Maybe<CmsPage>>>
  children?: Maybe<Array<Maybe<CmsPage>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsPageLink = {
  id?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  preview?: Maybe<Scalars['String']>
}

export type CmsPageSummary = {
  id?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  featured_image?: Maybe<CmsFeaturedImage>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsPostSummary = {
  id?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  preview?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  datetime?: Maybe<Scalars['Date']>
  author?: Maybe<Scalars['String']>
  featured_image?: Maybe<CmsFeaturedImage>
  tags?: Maybe<Array<Maybe<CmsTag>>>
  categories?: Maybe<Array<Maybe<CmsCategory>>>
  taxonomies?: Maybe<Array<Maybe<CmsCustomTaxonomyCollection>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsQuizAnswer = {
  answer?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsQuizQuestion = {
  question?: Maybe<Scalars['String']>
  answers?: Maybe<Array<Maybe<CmsQuizAnswer>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsQuote = {
  quote?: Maybe<Scalars['String']>
  author?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsRelatedContent = {
  id?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  image?: Maybe<CmsImage>
  tags?: Maybe<Array<Maybe<CmsTag>>>
  categories?: Maybe<Array<Maybe<CmsCategory>>>
  taxonomies?: Maybe<Array<Maybe<CmsCustomTaxonomyCollection>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsRelatedContentCollection = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  content?: Maybe<Array<Maybe<CmsRelatedContent>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsSearch = {
  total_results?: Maybe<Scalars['Int']>
  total_searched?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<CmsSearchResultType>>>
}

export type CmsSearchResultType = {
  type?: Maybe<Scalars['String']>
  results?: Maybe<Array<Maybe<CmsPostSummary>>>
}

export type CmsSkywayForm = {
  id?: Maybe<Scalars['OID']>
  form?: Maybe<Form>
}

export type CmsSocialLink = {
  type?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsSocialLinkCollection = {
  links?: Maybe<Array<Maybe<CmsSocialLink>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsSponsor = {
  name?: Maybe<Scalars['String']>
  image?: Maybe<CmsImage>
  link?: Maybe<CmsLink>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsSponsorCollection = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  sponsors?: Maybe<Array<Maybe<CmsSponsor>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsStaffMember = {
  name?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsStaffMemberCollection = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  staff?: Maybe<Array<Maybe<CmsStaffMember>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsStatistic = {
  statistic?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsStatisticCollection = {
  title?: Maybe<Scalars['String']>
  intro?: Maybe<Scalars['String']>
  statistics?: Maybe<Array<Maybe<CmsStatistic>>>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsTag = {
  id?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
}

export type CmsTaxonomy = {
  id?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  count?: Maybe<Scalars['Int']>
  parent?: Maybe<Scalars['OID']>
}

export type CmsTaxonomyInput = {
  object_refs?: Maybe<Scalars['String']>
  order_by?: Maybe<Scalars['String']>
  order?: Maybe<OrderEnum>
  hide_empty?: Maybe<Scalars['Boolean']>
  include?: Maybe<Scalars['String']>
  exclude?: Maybe<Scalars['String']>
  per_page?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
}

export type CmsTaxQueryInput = {
  taxonomy?: Maybe<Scalars['String']>
  field?: Maybe<Scalars['String']>
  terms?: Maybe<Scalars['String']>
}

export type CmsTextBlockWithTitle = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsTile = {
  title?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  link?: Maybe<CmsLink>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsTileCollection = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  tiles?: Maybe<CmsTile>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsTimeToRead = {
  minutes?: Maybe<Scalars['Int']>
  extra?: Maybe<Scalars['JSON']>
}

export type CmsTitle = {
  title?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type ContactPreferenceCategory = {
  category_ref: Scalars['OID']
  name: Scalars['String']
  ask_frequency_months?: Maybe<Scalars['Int']>
  types?: Maybe<Array<Maybe<ContactPreferenceType>>>
}

export type ContactPreferenceType = {
  type_ref: Scalars['OID']
  name: Scalars['String']
  category?: Maybe<ContactPreferenceCategory>
  description?: Maybe<Scalars['String']>
  images?: Maybe<Scalars['JSON']>
}

export type Country = {
  country_ref?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
  iso_code?: Maybe<Scalars['String']>
  requires_state?: Maybe<Scalars['Boolean']>
}

export type CreateCustomerInput = {
  customer: CustomerInput
  login: LoginInput
  preferences?: Maybe<Array<Maybe<CustomerContactPreferenceInput>>>
  interests?: Maybe<Array<Maybe<CustomerInterestInput>>>
}

export type CreateCustomerServiceActionInput = {
  action_ref?: Maybe<Scalars['OID']>
  date?: Maybe<Scalars['Date']>
  letter_printed_date?: Maybe<Scalars['Date']>
  customer_ref?: Maybe<Scalars['OID']>
  action_type_ref: Scalars['OID']
  issue_ref?: Maybe<Scalars['OID']>
  note?: Maybe<Scalars['String']>
  resolved?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CreateCustomerServiceIssueInput = {
  note: Scalars['String']
  activity_type_ref: Scalars['OID']
  activity_category_ref: Scalars['OID']
  contact_type_ref: Scalars['OID']
  customer_ref?: Maybe<Scalars['OID']>
  origin_ref?: Maybe<Scalars['OID']>
  instance_ref?: Maybe<Scalars['OID']>
  package_ref?: Maybe<Scalars['OID']>
  urgent?: Maybe<Scalars['Boolean']>
  resolved?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['Date']>
  actions?: Maybe<Array<Maybe<CreateCustomerServiceActionInput>>>
}

export type CreateEventInput = {
  title?: Maybe<Scalars['String']>
}

export type CreateHouseholdInput = {
  address?: Maybe<CustomerAddressInput>
  adults?: Maybe<Array<Maybe<CustomerInput>>>
}

export type Customer = {
  customer_ref?: Maybe<Scalars['OID']>
  type_ref?: Maybe<Scalars['OID']>
  prefix_ref?: Maybe<Scalars['OID']>
  suffix_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  prefix?: Maybe<Scalars['String']>
  suffix?: Maybe<Scalars['String']>
  display_name?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  middle_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['Date']>
  date_of_birth?: Maybe<Scalars['Date']>
  age?: Maybe<Scalars['Int']>
  meta?: Maybe<CustomerMeta>
  loyalty_points?: Maybe<Scalars['Int']>
  loyalty_points_value?: Maybe<Scalars['Float']>
  status?: Maybe<Scalars['String']>
  primary?: Maybe<Scalars['Boolean']>
  preferences?: Maybe<Array<Maybe<CustomerContactPreference>>>
  interests?: Maybe<Array<Maybe<CustomerInterest>>>
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>
  billing_address?: Maybe<CustomerAddress>
  addresses?: Maybe<Array<Maybe<CustomerAddress>>>
  memberships?: Maybe<Array<Maybe<MembershipInstance>>>
  phones?: Maybe<Array<Maybe<CustomerPhone>>>
  emails?: Maybe<Array<Maybe<CustomerEmail>>>
  giftaid?: Maybe<Array<Maybe<GiftAidDeclaration>>>
  attributes?: Maybe<Array<Maybe<CustomerAttribute>>>
  research?: Maybe<Array<Maybe<CustomerResearch>>>
  constituencies?: Maybe<Array<Maybe<CustomerConstituency>>>
  tickets?: Maybe<Array<Maybe<Ticket>>>
  orders?: Maybe<Array<Maybe<Order>>>
  contributions?: Maybe<Array<Maybe<CustomerContribution>>>
  balances?: Maybe<Array<Maybe<CustomerBalance>>>
  payment_accounts?: Maybe<Array<Maybe<CustomerPaymentAccount>>>
  emergency_contacts?: Maybe<Array<Maybe<CustomerContact>>>
  rankings?: Maybe<Array<Maybe<CustomerRanking>>>
  adults?: Maybe<Array<Maybe<Customer>>>
  children?: Maybe<Array<Maybe<CustomerChild>>>
  issues?: Maybe<Array<Maybe<CustomerServiceIssue>>>
}

export type CustomerMembershipsArgs = {
  includeAffiliates?: Maybe<Scalars['Boolean']>
  current?: Maybe<Scalars['Boolean']>
}

export type CustomerAccount = {
  customer_ref?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  status: Scalars['Int']
}

export type CustomerAddress = {
  address_ref?: Maybe<Scalars['OID']>
  customer_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  type_ref?: Maybe<Scalars['OID']>
  label?: Maybe<Scalars['String']>
  street1: Scalars['String']
  street2?: Maybe<Scalars['String']>
  street3?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  state_ref?: Maybe<Scalars['OID']>
  city: Scalars['String']
  country?: Maybe<Scalars['String']>
  country_ref?: Maybe<Scalars['OID']>
  postal_code: Scalars['String']
  updated_at?: Maybe<Scalars['Date']>
  primary?: Maybe<Scalars['Boolean']>
}

export type CustomerAddressInput = {
  customer_ref?: Maybe<Scalars['OID']>
  address_ref?: Maybe<Scalars['OID']>
  type_ref?: Maybe<Scalars['OID']>
  label?: Maybe<Scalars['String']>
  street1: Scalars['String']
  street2?: Maybe<Scalars['String']>
  street3?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  state_ref?: Maybe<Scalars['OID']>
  city: Scalars['String']
  country?: Maybe<Scalars['String']>
  country_ref?: Maybe<Scalars['OID']>
  postal_code: Scalars['String']
  updated_at?: Maybe<Scalars['Date']>
  primary?: Maybe<Scalars['Boolean']>
}

export type CustomerAttribute = {
  attribute_ref: Scalars['OID']
  keyword?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
  keyword_ref: Scalars['OID']
  customer_ref?: Maybe<Scalars['OID']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerAttributeInput = {
  attribute_ref?: Maybe<Scalars['OID']>
  customer_ref?: Maybe<Scalars['OID']>
  keyword_ref: Scalars['OID']
  value?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerBalance = {
  customer_ref?: Maybe<Scalars['OID']>
  payment_method_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  balance?: Maybe<Scalars['Float']>
  used_in_basket?: Maybe<Scalars['Float']>
  current_balance?: Maybe<Scalars['Float']>
}

export type CustomerChild = {
  affiliation_ref?: Maybe<Scalars['OID']>
  customer_ref?: Maybe<Scalars['OID']>
  first_name: Scalars['String']
  last_name: Scalars['String']
  meta?: Maybe<CustomerMeta>
  school?: Maybe<Scalars['String']>
  date_of_birth?: Maybe<Scalars['Date']>
  relationship?: Maybe<Scalars['String']>
  research?: Maybe<Array<Maybe<CustomerResearch>>>
  associations?: Maybe<Array<Maybe<Association>>>
  attributes?: Maybe<Array<Maybe<CustomerAttribute>>>
  issues?: Maybe<Array<Maybe<CustomerServiceIssue>>>
}

export type CustomerChildInput = {
  affiliation_ref?: Maybe<Scalars['OID']>
  customer_ref?: Maybe<Scalars['OID']>
  first_name: Scalars['String']
  last_name: Scalars['String']
  meta?: Maybe<CustomerMetaInput>
  school?: Maybe<Scalars['String']>
  date_of_birth?: Maybe<Scalars['String']>
  relationship?: Maybe<Scalars['String']>
  research?: Maybe<Array<Maybe<CustomerResearchInput>>>
  emergency_contact?: Maybe<CustomerContactInput>
  authorised_pickups?: Maybe<Array<Maybe<CustomerContactInput>>>
}

export type CustomerConstituency = {
  constituency_ref?: Maybe<Scalars['OID']>
  type?: Maybe<CustomerConstituencyType>
  customer_ref?: Maybe<Scalars['OID']>
  start_date?: Maybe<Scalars['Date']>
  end_date?: Maybe<Scalars['Date']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerConstituencyInput = {
  constituency_ref?: Maybe<Scalars['OID']>
  customer_ref?: Maybe<Scalars['OID']>
  type?: Maybe<CustomerConstituencyTypeInput>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerConstituencyType = {
  constituency_type_ref?: Maybe<Scalars['OID']>
  constituency_type?: Maybe<Scalars['String']>
  rank?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
}

export type CustomerConstituencyTypeInput = {
  constituency_type_ref?: Maybe<Scalars['OID']>
}

export type CustomerContact = {
  association_ref?: Maybe<Scalars['OID']>
  type_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type CustomerContactInput = {
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  relationship?: Maybe<Scalars['String']>
}

export type CustomerContactPreference = {
  type_ref: Scalars['OID']
  name: Scalars['String']
  category?: Maybe<Scalars['String']>
  category_ref?: Maybe<Scalars['OID']>
  preference_ref?: Maybe<Scalars['OID']>
  should_ask?: Maybe<Scalars['Boolean']>
  last_asked?: Maybe<Scalars['Date']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerContactPreferenceInput = {
  type_ref?: Maybe<Scalars['OID']>
}

export type CustomerContribution = {
  contribution_ref?: Maybe<Scalars['OID']>
  date?: Maybe<Scalars['Date']>
  value?: Maybe<Scalars['Float']>
  campaign?: Maybe<Scalars['String']>
  fund_ref?: Maybe<Scalars['OID']>
  fund?: Maybe<Scalars['String']>
  designation?: Maybe<Scalars['String']>
  designation_ref?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
}

export type CustomerEmail = {
  email_ref?: Maybe<Scalars['OID']>
  customer_ref?: Maybe<Scalars['OID']>
  email?: Maybe<Scalars['String']>
  opt_in?: Maybe<Scalars['Boolean']>
  primary?: Maybe<Scalars['Boolean']>
  type?: Maybe<Scalars['String']>
  type_ref?: Maybe<Scalars['OID']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerEmailInput = {
  email_ref?: Maybe<Scalars['OID']>
  email: Scalars['String']
  opt_in?: Maybe<Scalars['Boolean']>
  primary?: Maybe<Scalars['Boolean']>
  type_ref?: Maybe<Scalars['OID']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerInput = {
  customer_ref?: Maybe<Scalars['OID']>
  type_ref?: Maybe<Scalars['OID']>
  prefix_ref?: Maybe<Scalars['OID']>
  prefix?: Maybe<Scalars['String']>
  suffix_ref?: Maybe<Scalars['OID']>
  suffix?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  middle_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  sort_name?: Maybe<Scalars['String']>
  meta?: Maybe<CustomerMetaInput>
  adults?: Maybe<Array<Maybe<CustomerInput>>>
  children?: Maybe<Array<Maybe<CustomerChildInput>>>
  emergency_contact?: Maybe<CustomerContactInput>
  addresses?: Maybe<Array<Maybe<CustomerAddressInput>>>
  date_of_birth?: Maybe<Scalars['Date']>
  updated_at?: Maybe<Scalars['Date']>
  allow_marketing?: Maybe<Scalars['Boolean']>
  primary?: Maybe<Scalars['Boolean']>
}

export type CustomerInterest = {
  type_ref: Scalars['OID']
  customer_ref?: Maybe<Scalars['OID']>
  name: Scalars['String']
  category?: Maybe<Scalars['String']>
  category_ref?: Maybe<Scalars['OID']>
  interest_ref?: Maybe<Scalars['OID']>
  weight?: Maybe<Scalars['Int']>
  selected?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerInterestInput = {
  type_ref: Scalars['OID']
  interest_ref?: Maybe<Scalars['OID']>
  weight?: Maybe<Scalars['Int']>
  selected?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerLogin = {
  username: Scalars['String']
  failed_attempts?: Maybe<Scalars['Int']>
  temporary?: Maybe<Scalars['Boolean']>
  primary?: Maybe<Scalars['Boolean']>
  customer_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  type_ref?: Maybe<Scalars['OID']>
  locked_date?: Maybe<Scalars['Date']>
  last_login_date?: Maybe<Scalars['Date']>
}

export type CustomerMeta = {
  gender?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  phone_ref?: Maybe<Scalars['OID']>
  address?: Maybe<CustomerAddress>
}

export type CustomerMetaInput = {
  gender?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  phone_ref?: Maybe<Scalars['OID']>
  other_phones?: Maybe<Array<Maybe<Scalars['String']>>>
  interests?: Maybe<Array<Maybe<Scalars['ID']>>>
  preferences?: Maybe<Array<Maybe<Scalars['ID']>>>
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>
  address?: Maybe<CustomerAddressInput>
}

export type CustomerPaymentAccount = {
  account_ref?: Maybe<Scalars['OID']>
  account_number?: Maybe<Scalars['String']>
  account_name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  type_ref?: Maybe<Scalars['OID']>
  direct_debit_type?: Maybe<Scalars['String']>
  direct_debit_type_ref?: Maybe<Scalars['OID']>
  expiry_month?: Maybe<Scalars['Int']>
  expiry_year?: Maybe<Scalars['Int']>
  customer_ref?: Maybe<Scalars['OID']>
  active?: Maybe<Scalars['Boolean']>
  last_four?: Maybe<Scalars['String']>
  first_six?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['OID']>
  created_date?: Maybe<Scalars['Date']>
  updated_date?: Maybe<Scalars['Date']>
}

export type CustomerPhone = {
  phone_ref?: Maybe<Scalars['OID']>
  address_ref?: Maybe<Scalars['OID']>
  customer_ref?: Maybe<Scalars['OID']>
  number?: Maybe<Scalars['String']>
  primary?: Maybe<Scalars['Boolean']>
  opt_in?: Maybe<Scalars['Boolean']>
  type?: Maybe<Scalars['String']>
  type_ref?: Maybe<Scalars['OID']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerPhoneInput = {
  phone_ref?: Maybe<Scalars['OID']>
  address_ref?: Maybe<Scalars['OID']>
  number: Scalars['String']
  opt_in?: Maybe<Scalars['Boolean']>
  type_ref?: Maybe<Scalars['OID']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerRanking = {
  ranking_ref?: Maybe<Scalars['OID']>
  rank?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  type_ref?: Maybe<Scalars['OID']>
}

export type CustomerResearch = {
  research_ref: Scalars['OID']
  customer_ref: Scalars['OID']
  date?: Maybe<Scalars['Date']>
  value: Scalars['String']
  type_ref: Scalars['OID']
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerResearchInput = {
  research_ref?: Maybe<Scalars['OID']>
  customer_ref?: Maybe<Scalars['OID']>
  date?: Maybe<Scalars['Date']>
  type_ref: Scalars['OID']
  value: Scalars['String']
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerServiceAction = {
  action_ref?: Maybe<Scalars['OID']>
  description?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['Date']>
  letter_printed_date?: Maybe<Scalars['Date']>
  customer_ref?: Maybe<Scalars['OID']>
  action_type_ref: Scalars['OID']
  issue_ref: Scalars['OID']
  note?: Maybe<Scalars['String']>
  resolved?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['Date']>
}

export type CustomerServiceIssue = {
  issue_ref: Scalars['OID']
  activity_type_ref?: Maybe<Scalars['OID']>
  activity_category_ref: Scalars['OID']
  contact_type_ref: Scalars['OID']
  customer_ref?: Maybe<Scalars['OID']>
  origin_ref?: Maybe<Scalars['OID']>
  instance_ref?: Maybe<Scalars['OID']>
  package_ref?: Maybe<Scalars['OID']>
  urgent?: Maybe<Scalars['Boolean']>
  resolved?: Maybe<Scalars['Boolean']>
  issue_date?: Maybe<Scalars['Date']>
  updated_at?: Maybe<Scalars['Date']>
  note: Scalars['String']
  actions?: Maybe<Array<Maybe<CustomerServiceAction>>>
}

export type CustomInput = {
  name?: Maybe<Scalars['String']>
}

export type CustomListInput = {
  list_ref: Scalars['OID']
  output_set_ref?: Maybe<Scalars['OID']>
  page?: Maybe<Scalars['Int']>
  page_size?: Maybe<Scalars['Int']>
  generate?: Maybe<Scalars['Boolean']>
  format_date?: Maybe<Scalars['Boolean']>
}

export type CustomMessage = {
  id?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  language?: Maybe<Scalars['String']>
  page?: Maybe<CustomMessagePage>
}

export type CustomMessagePage = {
  id?: Maybe<Scalars['OID']>
  slug?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type CustomOutput = {
  response?: Maybe<Scalars['JSON']>
}

export type DateRangeInput = {
  from?: Maybe<Scalars['Date']>
  to?: Maybe<Scalars['Date']>
}

export type Day = {
  date?: Maybe<Scalars['Int']>
  dow?: Maybe<Scalars['Int']>
  instances?: Maybe<Array<Maybe<Instance>>>
}

export type DeliveryMethod = {
  delivery_method_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  default?: Maybe<Scalars['Boolean']>
  restricted?: Maybe<Scalars['Boolean']>
  print_at_home?: Maybe<Scalars['Boolean']>
  require_address?: Maybe<Scalars['Boolean']>
}

export type Designation = {
  id?: Maybe<Scalars['OID']>
  designation_ref?: Maybe<Scalars['OID']>
  campaign_ref?: Maybe<Scalars['OID']>
  membership_org_ref?: Maybe<Scalars['OID']>
  designation_detail_ref?: Maybe<Scalars['OID']>
  designation_detail_id?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  financial_year?: Maybe<Scalars['Int']>
  campaign?: Maybe<Scalars['String']>
  membership_org?: Maybe<Scalars['String']>
  details?: Maybe<DesignationDetail>
}

export type DesignationDetail = {
  id?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  benefits?: Maybe<Scalars['String']>
  short_description?: Maybe<Scalars['String']>
  special_offer_text?: Maybe<Scalars['String']>
  images?: Maybe<Scalars['JSON']>
  extra?: Maybe<Scalars['JSON']>
  published?: Maybe<Scalars['Boolean']>
  on_sale?: Maybe<Scalars['Boolean']>
  special_offer?: Maybe<Scalars['Boolean']>
  featured?: Maybe<Scalars['Boolean']>
  designations?: Maybe<Array<Maybe<Designation>>>
  products?: Maybe<Array<Maybe<Event>>>
}

export type DirectDebitAccountDetails = {
  account_number: Scalars['String']
  sort_code: Scalars['String']
}

export type DirectDebitAccountOwnerDetails = {
  first_name: Scalars['String']
  last_name: Scalars['String']
  address_ref: Scalars['String']
  account: DirectDebitAccountDetails
}

export type DirectDebitAccountOwnershipVerification = {
  verified?: Maybe<Scalars['Boolean']>
  recommend_status?: Maybe<Scalars['Int']>
  sort_code_status?: Maybe<Scalars['Int']>
  account_number_status?: Maybe<Scalars['Int']>
}

export type DirectDebitAccountVerification = {
  valid?: Maybe<Scalars['Boolean']>
  invalid_reason?: Maybe<Scalars['String']>
}

export type DirectDebitAddress = {
  address_ref?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  street1?: Maybe<Scalars['String']>
  street2?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  postcode?: Maybe<Scalars['String']>
}

export type Email = {
  email?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
}

export type EmailInput = {
  sender: EmailSender
  recipient: EmailRecipient
  template_code?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type EmailRecipient = {
  name: Scalars['String']
  email: Scalars['String']
}

export type EmailSender = {
  name: Scalars['String']
}

export type EmailTemplate = {
  id?: Maybe<Scalars['OID']>
  code?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
}

export type Event = {
  id?: Maybe<Scalars['OID']>
  event_ref?: Maybe<Scalars['OID']>
  production_ref?: Maybe<Scalars['OID']>
  season_ref?: Maybe<Scalars['OID']>
  series_id?: Maybe<Scalars['Int']>
  venue_id?: Maybe<Scalars['Int']>
  facility_id?: Maybe<Scalars['Int']>
  production_title?: Maybe<Scalars['String']>
  season_title?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  first_date?: Maybe<Scalars['Date']>
  last_date?: Maybe<Scalars['Date']>
  publish_up?: Maybe<Scalars['Date']>
  publish_down?: Maybe<Scalars['Date']>
  alias?: Maybe<Scalars['String']>
  availability?: Maybe<Scalars['Int']>
  limited_availability?: Maybe<Scalars['Int']>
  max_tickets?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['String']>
  previews?: Maybe<Scalars['String']>
  running_time?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  age_suitability?: Maybe<Scalars['String']>
  by_line?: Maybe<Scalars['String']>
  booking_info?: Maybe<Scalars['String']>
  booking_link?: Maybe<Scalars['String']>
  preview_info?: Maybe<Scalars['String']>
  hash_tag?: Maybe<Scalars['String']>
  short_description?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  additional?: Maybe<Scalars['String']>
  credits?: Maybe<Scalars['String']>
  access?: Maybe<Scalars['String']>
  miscellaneous?: Maybe<Scalars['String']>
  external_booking?: Maybe<Scalars['String']>
  special_requests_label?: Maybe<Scalars['String']>
  images?: Maybe<Scalars['JSON']>
  galleries?: Maybe<Scalars['JSON']>
  gallery_objects?: Maybe<Scalars['JSON']>
  videos?: Maybe<Scalars['JSON']>
  sponsors?: Maybe<Scalars['JSON']>
  attachments?: Maybe<Scalars['JSON']>
  accessibility?: Maybe<Scalars['JSON']>
  extra?: Maybe<Scalars['JSON']>
  booking_fee?: Maybe<Scalars['Float']>
  web_content?: Maybe<Array<Maybe<WebContent>>>
  custom?: Maybe<Scalars['Boolean']>
  on_sale?: Maybe<Scalars['Boolean']>
  published?: Maybe<Scalars['Boolean']>
  private?: Maybe<Scalars['Boolean']>
  featured?: Maybe<Scalars['Boolean']>
  general_admission?: Maybe<Scalars['Boolean']>
  free?: Maybe<Scalars['Boolean']>
  virtual?: Maybe<Scalars['Boolean']>
  special_requests?: Maybe<Scalars['Boolean']>
  recipient_info?: Maybe<Scalars['Boolean']>
  packaged?: Maybe<Scalars['Boolean']>
  next_instance?: Maybe<Instance>
  instances?: Maybe<Array<Maybe<Instance>>>
  artists?: Maybe<Array<Maybe<Artist>>>
  reviews?: Maybe<Array<Maybe<Review>>>
  series?: Maybe<Series>
  venue?: Maybe<Venue>
  facility?: Maybe<Facility>
  tags?: Maybe<Array<Maybe<Tag>>>
  modes_of_sale?: Maybe<Array<Maybe<ModeOfSale>>>
  related?: Maybe<Array<Maybe<Event>>>
  auxiliary?: Maybe<Array<Maybe<Event>>>
  delivery_methods?: Maybe<Array<Maybe<DeliveryMethod>>>
  forms?: Maybe<Array<Maybe<Form>>>
  notes?: Maybe<Array<Maybe<Note>>>
}

export type EventInstancesArgs = {
  range?: Maybe<DateRangeInput>
}

export type EventSummary = {
  id?: Maybe<Scalars['OID']>
  event_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  short_description?: Maybe<Scalars['String']>
  first_date?: Maybe<Scalars['Date']>
  last_date?: Maybe<Scalars['Date']>
  venue?: Maybe<Venue>
  facility?: Maybe<Facility>
  tags?: Maybe<Array<Maybe<Tag>>>
  instances?: Maybe<Array<Maybe<InstanceSummary>>>
  series?: Maybe<Series>
  extra?: Maybe<Scalars['JSON']>
}

export type EventType = {
  type?: Maybe<Scalars['String']>
}

export type ExecuteInput = {
  procedure_ref?: Maybe<Scalars['OID']>
  procedure_name?: Maybe<Scalars['String']>
  values?: Maybe<Array<Maybe<NameValuePair>>>
}

export type Facility = {
  id?: Maybe<Scalars['OID']>
  facility_ref?: Maybe<Scalars['OID']>
  venue_id?: Maybe<Scalars['Int']>
  venue_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  alias?: Maybe<Scalars['String']>
  address1?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  postcode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  opening_hours?: Maybe<Scalars['String']>
  opening_times?: Maybe<Scalars['JSON']>
  website?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  public_transport?: Maybe<Scalars['String']>
  parking?: Maybe<Scalars['String']>
  accessibility?: Maybe<Scalars['String']>
  concessions?: Maybe<Scalars['String']>
  policies?: Maybe<Scalars['String']>
  pre_event_info?: Maybe<Scalars['String']>
  post_event_info?: Maybe<Scalars['String']>
  images?: Maybe<Scalars['JSON']>
  attachments?: Maybe<Scalars['JSON']>
  extra?: Maybe<Scalars['JSON']>
  parking_options?: Maybe<Scalars['JSON']>
  lat?: Maybe<Scalars['Float']>
  lng?: Maybe<Scalars['Float']>
  directions?: Maybe<Scalars['String']>
  seats?: Maybe<Scalars['Int']>
  venue?: Maybe<Scalars['String']>
  published?: Maybe<Scalars['Boolean']>
  featured?: Maybe<Scalars['Boolean']>
  instances?: Maybe<Array<Maybe<Instance>>>
  events?: Maybe<Array<Maybe<Event>>>
  seatmap?: Maybe<SeatMap>
}

export type Fee = {
  amount?: Maybe<Scalars['Float']>
  payment_method_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  item_ref?: Maybe<Scalars['OID']>
  sub_item_ref?: Maybe<Scalars['OID']>
  type_ref?: Maybe<Scalars['OID']>
}

export type Form = {
  id?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  fields?: Maybe<Scalars['JSON']>
  actions?: Maybe<Scalars['JSON']>
  confirmation_text?: Maybe<Scalars['String']>
  one_per_ticket?: Maybe<Scalars['Boolean']>
  requires_login?: Maybe<Scalars['Boolean']>
  defer?: Maybe<Scalars['Boolean']>
  event_ref?: Maybe<Scalars['OID']>
  submit_url?: Maybe<Scalars['String']>
}

export type FormOutput = {
  response?: Maybe<Scalars['JSON']>
}

export type FormTicket = {
  price_type_ref?: Maybe<Scalars['OID']>
  price_type?: Maybe<Scalars['String']>
  zone_ref?: Maybe<Scalars['OID']>
  zone?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
}

export type Fund = {
  id?: Maybe<Scalars['OID']>
  fund_ref?: Maybe<Scalars['OID']>
  constituency_ref?: Maybe<Scalars['OID']>
  on_account_payment_ref?: Maybe<Scalars['OID']>
  notes?: Maybe<Array<Maybe<Note>>>
  title?: Maybe<Scalars['String']>
  short_description?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  images?: Maybe<Scalars['JSON']>
  extra?: Maybe<Scalars['JSON']>
  published?: Maybe<Scalars['Boolean']>
  on_sale?: Maybe<Scalars['Boolean']>
}

export type GetSeatsInput = {
  instance_ref: Scalars['OID']
  zones?: Maybe<Array<Maybe<Scalars['OID']>>>
  sections?: Maybe<Array<Maybe<Scalars['OID']>>>
  screens?: Maybe<Array<Maybe<Scalars['OID']>>>
  types?: Maybe<Array<Maybe<Scalars['String']>>>
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type GiftAidDeclaration = {
  declaration_ref?: Maybe<Scalars['OID']>
  customer_ref: Scalars['OID']
  document_status?: Maybe<Scalars['String']>
  document_status_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  type_ref: Scalars['OID']
  received_date_time?: Maybe<Scalars['Date']>
  start_date_time?: Maybe<Scalars['Date']>
  end_date_time?: Maybe<Scalars['Date']>
  notes?: Maybe<Scalars['String']>
  eligible: Scalars['Boolean']
  ineligible_reason?: Maybe<Scalars['String']>
  ineligible_reason_ref?: Maybe<Scalars['OID']>
  contact_method?: Maybe<Scalars['String']>
  contact_method_ref?: Maybe<Scalars['OID']>
  updated_at?: Maybe<Scalars['Date']>
}

export type GiftAidDeclarationInput = {
  contact_method_ref?: Maybe<Scalars['OID']>
  document_status_ref?: Maybe<Scalars['OID']>
  ineligible_reason_ref?: Maybe<Scalars['OID']>
  type_ref: Scalars['OID']
  eligible: Scalars['Boolean']
  start_date_time?: Maybe<Scalars['Date']>
  end_date_time?: Maybe<Scalars['Date']>
  received_date_time?: Maybe<Scalars['Date']>
  note?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['Date']>
}

export type GiftCertificate = {
  gift_certificate_number?: Maybe<Scalars['String']>
  original_amount?: Maybe<Scalars['Float']>
  used_amount?: Maybe<Scalars['Float']>
  balance?: Maybe<Scalars['Float']>
  original_date?: Maybe<Scalars['Date']>
  batch_ref?: Maybe<Scalars['OID']>
  session_key?: Maybe<Scalars['String']>
  recipient_name?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
}

export type HoldCode = {
  hold_code_ref?: Maybe<Scalars['OID']>
  type_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  legend?: Maybe<Scalars['String']>
  blackout?: Maybe<Scalars['Boolean']>
}

export type IdLoginInput = {
  customer_ref?: Maybe<Scalars['OID']>
  password: Scalars['String']
}

export type Instance = {
  id?: Maybe<Scalars['OID']>
  event_id?: Maybe<Scalars['Int']>
  venue_id?: Maybe<Scalars['Int']>
  event_ref?: Maybe<Scalars['OID']>
  instance_ref?: Maybe<Scalars['OID']>
  facility_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  date_time?: Maybe<Scalars['Date']>
  publish_up?: Maybe<Scalars['Date']>
  publish_down?: Maybe<Scalars['Date']>
  time_option?: Maybe<Scalars['String']>
  time_custom?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  alias?: Maybe<Scalars['String']>
  total_availability?: Maybe<Scalars['Int']>
  wheelchair_availability?: Maybe<Scalars['Int']>
  availability?: Maybe<Array<Maybe<Availability>>>
  customer_status?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['Int']>
  images?: Maybe<Scalars['JSON']>
  extra?: Maybe<Scalars['JSON']>
  on_sale?: Maybe<Scalars['Boolean']>
  published?: Maybe<Scalars['Boolean']>
  custom?: Maybe<Scalars['Boolean']>
  show_promo_code?: Maybe<Scalars['Boolean']>
  general_admissions?: Maybe<Scalars['Boolean']>
  packaged?: Maybe<Scalars['Boolean']>
  event?: Maybe<Event>
  venue?: Maybe<Venue>
  facility?: Maybe<Facility>
  prices?: Maybe<Array<Maybe<ZonePrice>>>
  price_types?: Maybe<Array<Maybe<PriceType>>>
  all_price_types?: Maybe<Array<Maybe<PriceType>>>
  tags?: Maybe<Array<Maybe<Tag>>>
  modes_of_sale?: Maybe<Array<Maybe<ModeOfSale>>>
  forms?: Maybe<Array<Maybe<Form>>>
}

export type InstanceTotal_AvailabilityArgs = {
  bust_cache?: Maybe<Scalars['Boolean']>
}

export type InstanceWheelchair_AvailabilityArgs = {
  bust_cache?: Maybe<Scalars['Boolean']>
}

export type InstanceAvailabilityArgs = {
  bust_cache?: Maybe<Scalars['Boolean']>
}

export type InstanceStatus = {
  published?: Maybe<Scalars['Boolean']>
  instance_ref?: Maybe<Scalars['OID']>
}

export type InstanceSummary = {
  id?: Maybe<Scalars['OID']>
  instance_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  date_time?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  alias?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['Int']>
  images?: Maybe<Scalars['JSON']>
  extra?: Maybe<Scalars['JSON']>
  on_sale?: Maybe<Scalars['Boolean']>
  published?: Maybe<Scalars['Boolean']>
  custom?: Maybe<Scalars['Boolean']>
  show_promo_code?: Maybe<Scalars['Boolean']>
  general_admissions?: Maybe<Scalars['Boolean']>
  tags?: Maybe<Array<Maybe<Tag>>>
}

export type InterestType = {
  type_ref?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  category_ref?: Maybe<Scalars['OID']>
}

export type KeyValue = {
  key: Scalars['String']
  value: Scalars['JSON']
  created_at?: Maybe<Scalars['Date']>
  expires_at?: Maybe<Scalars['Date']>
}

export type KeyValueInput = {
  key: Scalars['String']
  value: Scalars['JSON']
  expires?: Maybe<Scalars['Int']>
}

export type LoginInput = {
  username: Scalars['String']
  password: Scalars['String']
  password_confirm?: Maybe<Scalars['String']>
}

export type LoyaltySchemeLedger = {
  customer_ref?: Maybe<Scalars['OID']>
  order_ref?: Maybe<Scalars['OID']>
  points?: Maybe<Scalars['Int']>
  transaction_type?: Maybe<Scalars['String']>
  transaction_value?: Maybe<Scalars['Float']>
  created_date?: Maybe<Scalars['Date']>
  updated_date?: Maybe<Scalars['Date']>
}

export type Membership = {
  id?: Maybe<Scalars['OID']>
  fund_id?: Maybe<Scalars['Int']>
  fund_ref?: Maybe<Scalars['OID']>
  level_ref?: Maybe<Scalars['OID']>
  mode_of_sale_ref?: Maybe<Scalars['OID']>
  membership_group_ref?: Maybe<Scalars['OID']>
  membership_organisation_ref?: Maybe<Scalars['OID']>
  on_account_payment_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  membership_group?: Maybe<Scalars['String']>
  membership_organisation?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  benefits?: Maybe<Scalars['String']>
  other_benefits?: Maybe<Scalars['String']>
  start_amount?: Maybe<Scalars['Float']>
  end_amount?: Maybe<Scalars['Float']>
  extra?: Maybe<Scalars['JSON']>
  non_deductible_amount?: Maybe<Scalars['Float']>
  images?: Maybe<Scalars['JSON']>
  published?: Maybe<Scalars['Boolean']>
  on_sale?: Maybe<Scalars['Boolean']>
  fund?: Maybe<Fund>
  notes?: Maybe<Array<Maybe<Note>>>
}

export type MembershipDates = {
  initiation?: Maybe<Scalars['Date']>
  expiration?: Maybe<Scalars['Date']>
  inception?: Maybe<Scalars['Date']>
  lapse?: Maybe<Scalars['Date']>
  renewal?: Maybe<Scalars['Date']>
}

export type MembershipInstance = {
  membership_ref: Scalars['OID']
  current?: Maybe<Scalars['Boolean']>
  default?: Maybe<Scalars['Boolean']>
  declined_benefits?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  dates?: Maybe<MembershipDates>
  membership_type?: Maybe<Membership>
}

export type Message = {
  message?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  rule?: Maybe<Scalars['String']>
}

export type ModeOfSale = {
  mode_of_sale_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['Date']>
  end_date?: Maybe<Scalars['Date']>
}

export type Mutation = {
  addGiftCertificate?: Maybe<Scalars['OID']>
  removeGiftCertificate?: Maybe<Scalars['Boolean']>
  applyGiftCertificate?: Maybe<Scalars['Boolean']>
  unapplyGiftCertificate?: Maybe<Scalars['Boolean']>
  addTickets?: Maybe<Scalars['Boolean']>
  addTicket?: Maybe<Scalars['Boolean']>
  removeTicket?: Maybe<Scalars['Boolean']>
  removeTickets?: Maybe<Scalars['Boolean']>
  removeAllTickets?: Maybe<Scalars['Boolean']>
  removeTicketsByInstance?: Maybe<Scalars['Boolean']>
  addFixedPackage?: Maybe<Scalars['Boolean']>
  removeFixedPackage?: Maybe<Scalars['Boolean']>
  addFlexPackage?: Maybe<Scalars['Boolean']>
  removeFlexPackage?: Maybe<Scalars['Boolean']>
  removeFlexPackageInstance?: Maybe<Scalars['Boolean']>
  renewSubscription?: Maybe<Scalars['Boolean']>
  addContribution?: Maybe<Scalars['OID']>
  removeContribution?: Maybe<Scalars['Boolean']>
  removeContributions?: Maybe<Scalars['Boolean']>
  addMembership?: Maybe<Scalars['OID']>
  upgradeMembership?: Maybe<Scalars['Boolean']>
  renewMembership?: Maybe<Scalars['Boolean']>
  removeMembership?: Maybe<Scalars['Boolean']>
  addGiftMembership?: Maybe<Scalars['OID']>
  applyPromoCode?: Maybe<Scalars['Boolean']>
  resetPromoCode?: Maybe<Scalars['Boolean']>
  setDeliveryMethod?: Maybe<Scalars['Boolean']>
  setDeliveryAddress?: Maybe<Scalars['Boolean']>
  setDeliveryMethodAndAddress?: Maybe<Scalars['Boolean']>
  setModeOfSale?: Maybe<Scalars['Boolean']>
  setSource?: Maybe<Scalars['Boolean']>
  revertModeOfSale?: Maybe<Scalars['Boolean']>
  updateBasketExpiry?: Maybe<Scalars['Boolean']>
  checkInitiator?: Maybe<Scalars['Boolean']>
  clearBasket?: Maybe<Scalars['Boolean']>
  applyCreditToBasket?: Maybe<Scalars['Boolean']>
  removeCreditFromBasket?: Maybe<Scalars['Boolean']>
  applyLoyaltyPointsToBasket?: Maybe<Scalars['Boolean']>
  removeLoyaltyPointsFromBasket?: Maybe<Scalars['Boolean']>
  removeOnAccountPayment?: Maybe<Scalars['Boolean']>
  checkFees?: Maybe<Scalars['Boolean']>
  addSpecialRequest?: Maybe<Scalars['Boolean']>
  initiateCheckout?: Maybe<Scalars['String']>
  completeCheckout?: Maybe<Scalars['Boolean']>
  completeFreeCheckout?: Maybe<Scalars['Boolean']>
  directDebitCheckout?: Maybe<Scalars['Boolean']>
  rsvp?: Maybe<Scalars['Boolean']>
  applyGiftMembership?: Maybe<Scalars['Boolean']>
  updateLoyaltyPoints?: Maybe<Scalars['Boolean']>
  addDirectDebitAccount?: Maybe<CustomerPaymentAccount>
  saveOrder?: Maybe<Scalars['Boolean']>
  loadOrder?: Maybe<Basket>
  reprintOrder?: Maybe<Scalars['Boolean']>
  sendCMSEmail?: Maybe<CmsEmailResponse>
  addCustomData?: Maybe<CustomOutput>
  execute?: Maybe<CustomOutput>
  login?: Maybe<TokenSet>
  tokenLogin?: Maybe<TokenSet>
  socialLogin?: Maybe<TokenSet>
  guestLogin?: Maybe<TokenSet>
  idLogin?: Maybe<TokenSet>
  createWebLogin?: Maybe<Scalars['Boolean']>
  reauthenticate?: Maybe<TokenSet>
  activateAccount?: Maybe<TokenSet>
  createCustomer?: Maybe<TokenSet>
  convertToHousehold?: Maybe<TokenSet>
  convertToHouseholdDefault?: Maybe<TokenSet>
  createHousehold?: Maybe<Customer>
  createConstituent?: Maybe<Customer>
  newsletterSignup?: Maybe<Customer>
  updateCustomer?: Maybe<Customer>
  updateContactPreferences?: Maybe<Scalars['Boolean']>
  updateInterests?: Maybe<Scalars['Boolean']>
  updateInterest?: Maybe<Scalars['Boolean']>
  deleteInterest?: Maybe<Scalars['Boolean']>
  deleteInterestByType?: Maybe<Scalars['Boolean']>
  updateAttributes?: Maybe<Scalars['Boolean']>
  updateActiveCustomerAttributes?: Maybe<Scalars['Boolean']>
  updateAttribute?: Maybe<Scalars['Boolean']>
  deleteAttribute?: Maybe<Scalars['Boolean']>
  updateActiveCustomerResearch?: Maybe<Scalars['Boolean']>
  updateResearch?: Maybe<Scalars['Boolean']>
  deleteResearch?: Maybe<Scalars['Boolean']>
  updateConstituencies?: Maybe<Scalars['Boolean']>
  updateActiveCustomerConstituencies?: Maybe<Scalars['Boolean']>
  updateConstituency?: Maybe<Scalars['Boolean']>
  deleteConstituency?: Maybe<Scalars['Boolean']>
  setDateOfBirth?: Maybe<Scalars['Boolean']>
  setBillingAddress?: Maybe<Scalars['Boolean']>
  updateAddresses?: Maybe<Scalars['Boolean']>
  updateAddress?: Maybe<Scalars['Boolean']>
  deleteAddress?: Maybe<Scalars['Boolean']>
  updatePhones?: Maybe<Scalars['Boolean']>
  updatePhone?: Maybe<Scalars['Boolean']>
  deletePhone?: Maybe<Scalars['Boolean']>
  updateEmails?: Maybe<Scalars['Boolean']>
  updateEmail?: Maybe<Scalars['Boolean']>
  deleteEmail?: Maybe<Scalars['Boolean']>
  sendOrderConfirmation?: Maybe<Scalars['Boolean']>
  sendTickets?: Maybe<Scalars['Boolean']>
  updatePassword?: Maybe<Scalars['Boolean']>
  updateLogin?: Maybe<Scalars['Boolean']>
  attachBasketToUserSession?: Maybe<Scalars['Boolean']>
  createCustomerServiceIssue?: Maybe<CustomerServiceIssue>
  updateCustomerServiceIssue?: Maybe<CustomerServiceIssue>
  createAdminCustomerServiceIssue?: Maybe<CustomerServiceIssue>
  createCustomerServiceAction?: Maybe<CustomerServiceAction>
  deleteCustomerServiceIssueByActivityType?: Maybe<Scalars['Boolean']>
  updateGiftAidDeclarations?: Maybe<Scalars['Boolean']>
  joinLoyaltyScheme?: Maybe<Scalars['Boolean']>
  donateLoyaltyPoints?: Maybe<Scalars['Boolean']>
  applyLoyaltyPointsToCustomer?: Maybe<Scalars['Boolean']>
  createHouseholdMember?: Maybe<Customer>
  updateHouseholdMember?: Maybe<Customer>
  removeHouseholdMember?: Maybe<Scalars['Boolean']>
  createConstituentAndAssociation?: Maybe<Customer>
  updateActiveCustomerAssociation?: Maybe<Scalars['Boolean']>
  updateActiveCustomerAffiliation?: Maybe<Scalars['Boolean']>
  updateAssociation?: Maybe<Scalars['Boolean']>
  deleteAssociation?: Maybe<Scalars['Boolean']>
  updateAffiliation?: Maybe<Scalars['Boolean']>
  removeAffiliation?: Maybe<Scalars['Boolean']>
  updateSalutation?: Maybe<Scalars['Boolean']>
  sendEmail?: Maybe<Scalars['Boolean']>
  sendCustomerEmail?: Maybe<Scalars['Boolean']>
  sendOrderErrorEmail?: Maybe<Scalars['Boolean']>
  preProcessForm?: Maybe<Scalars['JSON']>
  submitForm?: Maybe<Scalars['JSON']>
  sendNote?: Maybe<Scalars['Boolean']>
  createEvent?: Maybe<Event>
  addKeyValue?: Maybe<KeyValue>
  updateKeyValue?: Maybe<KeyValue>
  deleteKeyValue?: Maybe<Scalars['Boolean']>
}

export type MutationAddGiftCertificateArgs = {
  addGiftCertificateInput?: Maybe<AddGiftCertificateInput>
}

export type MutationRemoveGiftCertificateArgs = {
  gc_no?: Maybe<Scalars['String']>
}

export type MutationApplyGiftCertificateArgs = {
  code?: Maybe<Scalars['String']>
}

export type MutationUnapplyGiftCertificateArgs = {
  gc_no?: Maybe<Scalars['String']>
}

export type MutationAddTicketsArgs = {
  addTicketsInput?: Maybe<AddTicketsInput>
}

export type MutationAddTicketArgs = {
  addTicketInput?: Maybe<AddTicketInput>
}

export type MutationRemoveTicketArgs = {
  li_id?: Maybe<Scalars['OID']>
  sli_id?: Maybe<Scalars['OID']>
}

export type MutationRemoveTicketsArgs = {
  li_id?: Maybe<Scalars['OID']>
  instance_ref?: Maybe<Scalars['OID']>
}

export type MutationRemoveTicketsByInstanceArgs = {
  instance_ref?: Maybe<Scalars['OID']>
}

export type MutationAddFixedPackageArgs = {
  addFixedPackageInput?: Maybe<AddFixedPackageInput>
}

export type MutationRemoveFixedPackageArgs = {
  pkg_id?: Maybe<Scalars['OID']>
  li_id?: Maybe<Scalars['OID']>
}

export type MutationAddFlexPackageArgs = {
  addFlexPackageInput?: Maybe<AddFlexPackageInput>
}

export type MutationRemoveFlexPackageArgs = {
  li_id?: Maybe<Scalars['OID']>
}

export type MutationRemoveFlexPackageInstanceArgs = {
  pkg_li?: Maybe<Scalars['OID']>
  instance_li?: Maybe<Scalars['OID']>
  instance_id?: Maybe<Scalars['OID']>
}

export type MutationAddContributionArgs = {
  input?: Maybe<AddContributionInput>
}

export type MutationRemoveContributionArgs = {
  id?: Maybe<Scalars['OID']>
}

export type MutationAddMembershipArgs = {
  input?: Maybe<AddMembershipInput>
}

export type MutationUpgradeMembershipArgs = {
  input?: Maybe<AddMembershipInput>
}

export type MutationRenewMembershipArgs = {
  input?: Maybe<AddMembershipInput>
}

export type MutationRemoveMembershipArgs = {
  id?: Maybe<Scalars['OID']>
}

export type MutationAddGiftMembershipArgs = {
  addGiftMembershipInput?: Maybe<AddGiftCertificateInput>
}

export type MutationApplyPromoCodeArgs = {
  code?: Maybe<Scalars['String']>
}

export type MutationSetDeliveryMethodArgs = {
  id?: Maybe<Scalars['OID']>
}

export type MutationSetDeliveryAddressArgs = {
  id?: Maybe<Scalars['OID']>
}

export type MutationSetDeliveryMethodAndAddressArgs = {
  method_id?: Maybe<Scalars['OID']>
  address_id?: Maybe<Scalars['OID']>
}

export type MutationSetModeOfSaleArgs = {
  id?: Maybe<Scalars['OID']>
}

export type MutationSetSourceArgs = {
  id?: Maybe<Scalars['OID']>
}

export type MutationUpdateBasketExpiryArgs = {
  expiryTime?: Maybe<Scalars['String']>
}

export type MutationApplyCreditToBasketArgs = {
  applyCreditToBasketInput?: Maybe<ApplyCreditToBasketInput>
}

export type MutationRemoveCreditFromBasketArgs = {
  payment_id?: Maybe<Scalars['OID']>
}

export type MutationApplyLoyaltyPointsToBasketArgs = {
  points?: Maybe<Scalars['Int']>
}

export type MutationRemoveOnAccountPaymentArgs = {
  payment_id?: Maybe<Scalars['OID']>
}

export type MutationAddSpecialRequestArgs = {
  specialRequestInput?: Maybe<AddSpecialRequestInput>
}

export type MutationInitiateCheckoutArgs = {
  input: PaymentInput
  recaptcha: Scalars['String']
}

export type MutationCompleteCheckoutArgs = {
  payment: PaymentResponse
}

export type MutationDirectDebitCheckoutArgs = {
  membership?: Maybe<AddMembershipInput>
}

export type MutationRsvpArgs = {
  rsvpInput?: Maybe<RsvpInput>
}

export type MutationApplyGiftMembershipArgs = {
  applyGiftMembershipInput?: Maybe<ApplyGiftMembershipInput>
}

export type MutationUpdateLoyaltyPointsArgs = {
  orderRef?: Maybe<Scalars['OID']>
}

export type MutationAddDirectDebitAccountArgs = {
  account: AccountInput
}

export type MutationLoadOrderArgs = {
  order_ref: Scalars['OID']
}

export type MutationReprintOrderArgs = {
  order_ref: Scalars['OID']
}

export type MutationSendCmsEmailArgs = {
  params: CmsEmailInput
}

export type MutationAddCustomDataArgs = {
  name?: Maybe<Scalars['String']>
  values?: Maybe<Array<Maybe<NameValuePair>>>
}

export type MutationExecuteArgs = {
  executeInput?: Maybe<ExecuteInput>
}

export type MutationLoginArgs = {
  loginInput?: Maybe<LoginInput>
}

export type MutationTokenLoginArgs = {
  email?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

export type MutationSocialLoginArgs = {
  input: SocialLoginInput
}

export type MutationGuestLoginArgs = {
  email?: Maybe<Scalars['String']>
}

export type MutationIdLoginArgs = {
  loginInput?: Maybe<IdLoginInput>
}

export type MutationCreateWebLoginArgs = {
  email?: Maybe<Scalars['String']>
}

export type MutationReauthenticateArgs = {
  order_ref?: Maybe<Scalars['OID']>
}

export type MutationActivateAccountArgs = {
  input?: Maybe<ActivateAccountInput>
}

export type MutationCreateCustomerArgs = {
  createCustomerInput: CreateCustomerInput
  recaptcha: Scalars['String']
}

export type MutationCreateHouseholdArgs = {
  createHouseholdInput: CreateHouseholdInput
}

export type MutationCreateConstituentArgs = {
  customerInput?: Maybe<CustomerInput>
}

export type MutationNewsletterSignupArgs = {
  newsletterInput: NewsletterInput
  recaptcha: Scalars['String']
}

export type MutationUpdateCustomerArgs = {
  customerInput?: Maybe<CustomerInput>
}

export type MutationUpdateContactPreferencesArgs = {
  customerContactPreferencesInput?: Maybe<
    Array<Maybe<CustomerContactPreferenceInput>>
  >
}

export type MutationUpdateInterestsArgs = {
  customerInterestsInput?: Maybe<Array<Maybe<CustomerInterestInput>>>
}

export type MutationUpdateInterestArgs = {
  customerInterestInput?: Maybe<CustomerInterestInput>
}

export type MutationDeleteInterestArgs = {
  interest_ref: Scalars['OID']
}

export type MutationDeleteInterestByTypeArgs = {
  type_ref: Scalars['OID']
}

export type MutationUpdateAttributesArgs = {
  customerAttributesInput?: Maybe<Array<Maybe<CustomerAttributeInput>>>
}

export type MutationUpdateActiveCustomerAttributesArgs = {
  customerAttributesInput?: Maybe<Array<Maybe<CustomerAttributeInput>>>
}

export type MutationUpdateAttributeArgs = {
  customerAttributeInput?: Maybe<CustomerAttributeInput>
}

export type MutationDeleteAttributeArgs = {
  attribute_ref: Scalars['OID']
}

export type MutationUpdateActiveCustomerResearchArgs = {
  customerResearchInput?: Maybe<CustomerResearchInput>
}

export type MutationUpdateResearchArgs = {
  customerResearchInput?: Maybe<CustomerResearchInput>
}

export type MutationDeleteResearchArgs = {
  research_ref: Scalars['OID']
}

export type MutationUpdateConstituenciesArgs = {
  customerConstituenciesInput?: Maybe<Array<Maybe<CustomerConstituencyInput>>>
}

export type MutationUpdateActiveCustomerConstituenciesArgs = {
  customerConstituenciesInput?: Maybe<Array<Maybe<CustomerConstituencyInput>>>
}

export type MutationUpdateConstituencyArgs = {
  customerConstituencyInput?: Maybe<CustomerConstituencyInput>
}

export type MutationDeleteConstituencyArgs = {
  constituency_ref: Scalars['OID']
}

export type MutationSetDateOfBirthArgs = {
  dob: Scalars['String']
}

export type MutationSetBillingAddressArgs = {
  id?: Maybe<Scalars['OID']>
}

export type MutationUpdateAddressesArgs = {
  customerAddressesInput?: Maybe<Array<Maybe<CustomerAddressInput>>>
}

export type MutationUpdateAddressArgs = {
  customerAddressInput?: Maybe<CustomerAddressInput>
}

export type MutationDeleteAddressArgs = {
  address_ref?: Maybe<Scalars['OID']>
}

export type MutationUpdatePhonesArgs = {
  customerPhonesInput?: Maybe<Array<Maybe<CustomerPhoneInput>>>
}

export type MutationUpdatePhoneArgs = {
  customerPhoneInput?: Maybe<CustomerPhoneInput>
}

export type MutationDeletePhoneArgs = {
  phone_ref?: Maybe<Scalars['OID']>
}

export type MutationUpdateEmailsArgs = {
  customerEmailsInput?: Maybe<Array<Maybe<CustomerEmailInput>>>
}

export type MutationUpdateEmailArgs = {
  customerEmailInput?: Maybe<CustomerEmailInput>
}

export type MutationDeleteEmailArgs = {
  email_ref?: Maybe<Scalars['OID']>
}

export type MutationSendOrderConfirmationArgs = {
  order_ref?: Maybe<Scalars['OID']>
}

export type MutationSendTicketsArgs = {
  order_ref?: Maybe<Scalars['OID']>
}

export type MutationUpdatePasswordArgs = {
  updatePasswordInput: UpdatePasswordInput
}

export type MutationUpdateLoginArgs = {
  updateLoginInput: UpdateLoginInput
}

export type MutationAttachBasketToUserSessionArgs = {
  basketToken?: Maybe<Scalars['String']>
}

export type MutationCreateCustomerServiceIssueArgs = {
  createCustomerServiceIssueInput?: Maybe<CreateCustomerServiceIssueInput>
}

export type MutationUpdateCustomerServiceIssueArgs = {
  updateCustomerServiceIssueInput?: Maybe<UpdateCustomerServiceIssueInput>
}

export type MutationCreateAdminCustomerServiceIssueArgs = {
  createCustomerServiceIssueInput?: Maybe<CreateCustomerServiceIssueInput>
}

export type MutationCreateCustomerServiceActionArgs = {
  createCustomerServiceActionInput?: Maybe<CreateCustomerServiceActionInput>
}

export type MutationDeleteCustomerServiceIssueByActivityTypeArgs = {
  activity_type_ref?: Maybe<Scalars['OID']>
  customer_ref?: Maybe<Scalars['OID']>
}

export type MutationUpdateGiftAidDeclarationsArgs = {
  updateGiftAidDeclarationsInput?: Maybe<Array<Maybe<GiftAidDeclarationInput>>>
}

export type MutationApplyLoyaltyPointsToCustomerArgs = {
  customer_ref?: Maybe<Scalars['OID']>
  points?: Maybe<Scalars['Int']>
  order_ref?: Maybe<Scalars['OID']>
}

export type MutationCreateHouseholdMemberArgs = {
  customerInput?: Maybe<CustomerInput>
  type?: Maybe<Scalars['String']>
}

export type MutationUpdateHouseholdMemberArgs = {
  customerInput?: Maybe<CustomerInput>
  type?: Maybe<Scalars['String']>
}

export type MutationRemoveHouseholdMemberArgs = {
  customerRef?: Maybe<Scalars['OID']>
}

export type MutationCreateConstituentAndAssociationArgs = {
  customerInput: CustomerInput
  associationInput: AssociationInput
  addressInput?: Maybe<CustomerAddressInput>
}

export type MutationUpdateActiveCustomerAssociationArgs = {
  updateAssociationInput?: Maybe<AssociationInput>
}

export type MutationUpdateActiveCustomerAffiliationArgs = {
  updateAffiliationInput?: Maybe<AffiliationInput>
}

export type MutationUpdateAssociationArgs = {
  updateAssociationInput?: Maybe<AssociationInput>
}

export type MutationDeleteAssociationArgs = {
  association_ref?: Maybe<Scalars['OID']>
}

export type MutationUpdateAffiliationArgs = {
  updateAffiliationInput?: Maybe<AffiliationInput>
}

export type MutationRemoveAffiliationArgs = {
  removeAffiliationInput?: Maybe<RemoveAffiliationInput>
}

export type MutationSendEmailArgs = {
  emailInput: EmailInput
  recaptcha: Scalars['String']
}

export type MutationSendCustomerEmailArgs = {
  emailInput: EmailInput
}

export type MutationSendOrderErrorEmailArgs = {
  session_id: Scalars['String']
  error: Scalars['String']
}

export type MutationPreProcessFormArgs = {
  id?: Maybe<Scalars['OID']>
  data?: Maybe<Scalars['JSON']>
}

export type MutationSubmitFormArgs = {
  id?: Maybe<Scalars['OID']>
  data?: Maybe<Scalars['JSON']>
}

export type MutationSendNoteArgs = {
  content: Scalars['String']
  slug: Scalars['String']
  target_ref?: Maybe<Scalars['OID']>
  seat?: Maybe<Scalars['String']>
}

export type MutationCreateEventArgs = {
  createEventInput?: Maybe<CreateEventInput>
}

export type MutationAddKeyValueArgs = {
  keyValueInput?: Maybe<KeyValueInput>
}

export type MutationUpdateKeyValueArgs = {
  keyValueInput?: Maybe<KeyValueInput>
}

export type MutationDeleteKeyValueArgs = {
  key?: Maybe<Scalars['String']>
}

export type NameValuePair = {
  name?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type NewsletterInput = {
  first_name: Scalars['String']
  last_name: Scalars['String']
  email: Scalars['String']
  interests?: Maybe<Array<Maybe<CustomerInterestInput>>>
  preferences?: Maybe<Array<Maybe<CustomerContactPreferenceInput>>>
}

export type Note = {
  id?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  target_type?: Maybe<Scalars['String']>
  target_subtype?: Maybe<Scalars['String']>
  target_id?: Maybe<Scalars['OID']>
  activity_type_ref?: Maybe<Scalars['OID']>
  activity_category_ref?: Maybe<Scalars['OID']>
  contact_type_ref?: Maybe<Scalars['OID']>
  origin_ref?: Maybe<Scalars['OID']>
  urgent?: Maybe<Scalars['Boolean']>
  defer?: Maybe<Scalars['Boolean']>
}

export type Order = {
  order_ref: Scalars['OID']
  date: Scalars['Date']
  total: Scalars['Float']
  customer_ref?: Maybe<Scalars['OID']>
  initiator_ref?: Maybe<Scalars['OID']>
  delivery_method?: Maybe<Scalars['String']>
  delivery_method_ref?: Maybe<Scalars['OID']>
  delivery_address_ref?: Maybe<Scalars['OID']>
  total_paid?: Maybe<Scalars['Float']>
  groups?: Maybe<Array<Maybe<OrderGroup>>>
  order_fees?: Maybe<Array<Maybe<Fee>>>
  payments?: Maybe<Array<Maybe<Payment>>>
  loyalty_points_earned?: Maybe<Scalars['Int']>
  loyalty_points_redeemed?: Maybe<Scalars['Int']>
  total_count?: Maybe<Scalars['Int']>
}

export enum OrderEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderGroup = {
  name?: Maybe<Scalars['String']>
  items?: Maybe<Array<Maybe<OrderItem>>>
}

export type OrderItem = {
  item_ref?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  unit_price?: Maybe<Scalars['Float']>
  qty?: Maybe<Scalars['Int']>
  children?: Maybe<Array<Maybe<SubOrderItem>>>
  details?: Maybe<Event>
  extra?: Maybe<Scalars['JSON']>
}

export type Package = {
  id?: Maybe<Scalars['OID']>
  package_ref?: Maybe<Scalars['OID']>
  season_ref?: Maybe<Scalars['OID']>
  type_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  alias?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  images?: Maybe<Scalars['JSON']>
  description?: Maybe<Scalars['String']>
  access?: Maybe<Scalars['String']>
  additional?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  price_type?: Maybe<Scalars['String']>
  mode_of_sale?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['String']>
  booking_link?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
  min_instances?: Maybe<Scalars['Int']>
  max_instances?: Maybe<Scalars['Int']>
  fixed?: Maybe<Scalars['Boolean']>
  flex?: Maybe<Scalars['Boolean']>
  on_sale?: Maybe<Scalars['Boolean']>
  published?: Maybe<Scalars['Boolean']>
  featured?: Maybe<Scalars['Boolean']>
  custom?: Maybe<Scalars['Boolean']>
  zones?: Maybe<Array<Maybe<Zone>>>
  modes_of_sale?: Maybe<Array<Maybe<ModeOfSale>>>
  price_types?: Maybe<Array<Maybe<PriceType>>>
  prices?: Maybe<Array<Maybe<Price>>>
  instances?: Maybe<Array<Maybe<PackageInstance>>>
  tags?: Maybe<Array<Maybe<Tag>>>
  forms?: Maybe<Array<Maybe<Form>>>
  notes?: Maybe<Array<Maybe<Note>>>
}

export type PackagePrice_TypesArgs = {
  bust_cache?: Maybe<Scalars['Boolean']>
}

export type PackageInstance = {
  package_ref?: Maybe<Scalars['OID']>
  instance_ref?: Maybe<Scalars['OID']>
  group_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  date_time?: Maybe<Scalars['Date']>
  available?: Maybe<Scalars['Boolean']>
  notes?: Maybe<Scalars['String']>
  time_formatted?: Maybe<Scalars['String']>
  date_formatted?: Maybe<Scalars['String']>
}

export type Payment = {
  amount?: Maybe<Scalars['Float']>
  payment_method_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  item_ref?: Maybe<Scalars['OID']>
  extra?: Maybe<Scalars['JSON']>
}

export type PaymentCardRequest = {
  billing_name?: Maybe<Scalars['String']>
  billing_firstname?: Maybe<Scalars['String']>
  billing_lastname?: Maybe<Scalars['String']>
  billing_address1?: Maybe<Scalars['String']>
  billing_address2?: Maybe<Scalars['String']>
  billing_city?: Maybe<Scalars['String']>
  billing_state?: Maybe<Scalars['String']>
  billing_postcode?: Maybe<Scalars['String']>
  billing_country?: Maybe<Scalars['String']>
}

export type PaymentCardResponse = {
  card_holder_name: Scalars['String']
  card_number: Scalars['String']
  card_type: Scalars['String']
  expires?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

export type PaymentInput = {
  returnUrl: Scalars['String']
  cancelUrl: Scalars['String']
  recipient_customer_ref?: Maybe<Scalars['OID']>
}

export type PaymentMethod = {
  payment_method_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  type_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
}

export type PaymentRequest = {
  amount?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  transaction_id?: Maybe<Scalars['String']>
  returnUrl?: Maybe<Scalars['String']>
  cancelUrl?: Maybe<Scalars['String']>
  recipient_customer_ref?: Maybe<Scalars['OID']>
  token_payload?: Maybe<SessionToken>
  card?: Maybe<PaymentCardRequest>
  cardReference?: Maybe<Scalars['String']>
  expiry?: Maybe<Scalars['Date']>
}

export type PaymentRequestInput = {
  amount?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  transaction_id?: Maybe<Scalars['String']>
  returnUrl?: Maybe<Scalars['String']>
  cancelUrl?: Maybe<Scalars['String']>
  recipient_customer_ref?: Maybe<Scalars['OID']>
  token_payload?: Maybe<Scalars['JSON']>
}

export type PaymentResponse = {
  amount: Scalars['Float']
  type: Scalars['String']
  method: Scalars['String']
  auth_code: Scalars['String']
  txn_ref: Scalars['String']
  request: PaymentRequestInput
  card?: Maybe<PaymentCardResponse>
}

export type Prefix = {
  prefix_ref?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
}

export type Price = {
  price?: Maybe<Scalars['Float']>
  price_type_ref?: Maybe<Scalars['OID']>
  enabled?: Maybe<Scalars['Boolean']>
  is_base?: Maybe<Scalars['Boolean']>
  is_best?: Maybe<Scalars['Boolean']>
  is_offer?: Maybe<Scalars['Boolean']>
  instance_ref?: Maybe<Scalars['OID']>
  package_ref?: Maybe<Scalars['OID']>
  zone_ref?: Maybe<Scalars['OID']>
}

export type PriceType = {
  id?: Maybe<Scalars['OID']>
  price_type_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  information?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  base?: Maybe<Scalars['Boolean']>
  best?: Maybe<Scalars['Boolean']>
  offer?: Maybe<Scalars['Boolean']>
  prices?: Maybe<Array<Maybe<ZonePrice>>>
}

export type Query = {
  getBasket?: Maybe<Basket>
  getBasketFromSession?: Maybe<Basket>
  getBasketExpiry?: Maybe<Scalars['Date']>
  getBasketComplete?: Maybe<Scalars['Boolean']>
  adminGetBasketComplete?: Maybe<Scalars['Boolean']>
  getAllDeliveryMethods?: Maybe<Array<Maybe<DeliveryMethod>>>
  getAvailableDeliveryMethods?: Maybe<Array<Maybe<DeliveryMethod>>>
  getMessages?: Maybe<Array<Maybe<Message>>>
  getBasketProperties?: Maybe<BasketProperties>
  getBasketAddons?: Maybe<Array<Maybe<Event>>>
  getBasketForms?: Maybe<Array<Maybe<Form>>>
  getThirdPartyContactPermissions?: Maybe<Array<Maybe<ContactPreferenceType>>>
  validateBasket?: Maybe<Scalars['Boolean']>
  validateBasketFromSession?: Maybe<Scalars['Boolean']>
  getPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>
  getAllPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>
  getOnAccountPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>
  getVerfiedAddresses?: Maybe<Array<Maybe<DirectDebitAddress>>>
  checkBankAccountDetails?: Maybe<DirectDebitAccountVerification>
  verifyBankAccountOwnership?: Maybe<DirectDebitAccountOwnershipVerification>
  order?: Maybe<Order>
  orderHistory?: Maybe<Array<Maybe<Order>>>
  getCMSPage?: Maybe<CmsPage>
  getCMSMenu?: Maybe<CmsMenu>
  getCMSArchive?: Maybe<CmsArchive>
  getCMSTaxonomy?: Maybe<Array<Maybe<CmsTaxonomy>>>
  getCMSLayoutsByPage?: Maybe<Array<Maybe<CmsLayout>>>
  getCMSOptionsComponents?: Maybe<Array<Maybe<Scalars['JSON']>>>
  searchCMS?: Maybe<CmsSearch>
  custom?: Maybe<CustomOutput>
  customByRef?: Maybe<CustomOutput>
  getList?: Maybe<CustomOutput>
  getReferenceData?: Maybe<CustomOutput>
  customer?: Maybe<Customer>
  getActiveCustomer?: Maybe<Customer>
  getCustomerByRefAndName?: Maybe<Customer>
  getToken?: Maybe<TokenSet>
  refreshToken?: Maybe<TokenSet>
  transferSession?: Maybe<TokenSet>
  sendResetToken?: Maybe<Scalars['Boolean']>
  sendMagicLoginLink?: Maybe<Scalars['Boolean']>
  checkEmailExists?: Maybe<Scalars['Int']>
  checkCustomerHasLogin?: Maybe<Scalars['Boolean']>
  getStates?: Maybe<Array<Maybe<State>>>
  getCountries?: Maybe<Array<Maybe<Country>>>
  getPrefixes?: Maybe<Array<Maybe<Prefix>>>
  getSuffixes?: Maybe<Array<Maybe<Suffix>>>
  getContactPreferenceTypes?: Maybe<Array<Maybe<ContactPreferenceType>>>
  getContactPreferenceCategories?: Maybe<
    Array<Maybe<ContactPreferenceCategory>>
  >
  getInterestTypes?: Maybe<Array<Maybe<InterestType>>>
  logout?: Maybe<Scalars['Boolean']>
  getGiftCertificateBalance?: Maybe<GiftCertificate>
  checkPostcode?: Maybe<Array<Maybe<CustomerAddress>>>
  getCustomerServiceIssues?: Maybe<Array<Maybe<CustomerServiceIssue>>>
  getCustomerList?: Maybe<Array<Maybe<Customer>>>
  searchCustomersByType?: Maybe<Array<Maybe<Customer>>>
  getSuggestedEvents?: Maybe<Array<Maybe<Event>>>
  getRelationships?: Maybe<Array<Maybe<Relationship>>>
  getAssociations?: Maybe<Array<Maybe<Relationship>>>
  getAffiliations?: Maybe<Array<Maybe<Affiliation>>>
  getHousehold?: Maybe<Customer>
  getPrimaryHouseholdMembers?: Maybe<Array<Maybe<Customer>>>
  printTickets?: Maybe<Email>
  getOrderConfirmation?: Maybe<Email>
  getCustomerDetails?: Maybe<Email>
  form?: Maybe<Form>
  getForms?: Maybe<Array<Maybe<Form>>>
  getFormsByEventType?: Maybe<Array<Maybe<Form>>>
  getResearchTypes?: Maybe<Array<Maybe<ResearchType>>>
  getAssociationTypes?: Maybe<Array<Maybe<AssociationType>>>
  getAffiliationTypes?: Maybe<Array<Maybe<AffiliationType>>>
  getAttributeTypes?: Maybe<Array<Maybe<AttributeType>>>
  getDesignations?: Maybe<Array<Maybe<DesignationDetail>>>
  getRawDesignations?: Maybe<Array<Maybe<Designation>>>
  designation?: Maybe<DesignationDetail>
  getFunds?: Maybe<Array<Maybe<Fund>>>
  getRawFunds?: Maybe<Array<Maybe<Fund>>>
  fund?: Maybe<Fund>
  getMemberships?: Maybe<Array<Maybe<Membership>>>
  getMembershipsByOrg?: Maybe<Array<Maybe<Membership>>>
  getRawMemberships?: Maybe<Array<Maybe<Membership>>>
  membership?: Maybe<Membership>
  getMembershipByRef?: Maybe<Membership>
  getCustomerMemberships?: Maybe<Array<Maybe<MembershipInstance>>>
  getMembershipsByCustomerRef?: Maybe<Array<Maybe<MembershipInstance>>>
  getDonationsByCustomerRef?: Maybe<Array<Maybe<CustomerContribution>>>
  getNoteById?: Maybe<Note>
  getNoteBySlug?: Maybe<Note>
  getNotes?: Maybe<Array<Maybe<Note>>>
  getArtists?: Maybe<Array<Maybe<Artist>>>
  getArtistsByRole?: Maybe<Array<Maybe<Artist>>>
  artist?: Maybe<Artist>
  calendarMonth?: Maybe<Array<Maybe<Day>>>
  getEvents?: Maybe<Array<Maybe<Event>>>
  getAllEvents?: Maybe<Array<Maybe<Event>>>
  getArchiveEvents?: Maybe<Array<Maybe<Event>>>
  event?: Maybe<Event>
  getEventByRef?: Maybe<Event>
  getEventByAlias?: Maybe<Event>
  getEventBySlug?: Maybe<Event>
  getEventTypes?: Maybe<Array<Maybe<EventType>>>
  getRawEvents?: Maybe<Array<Maybe<Event>>>
  getRawEvent?: Maybe<Event>
  getFacilities?: Maybe<Array<Maybe<Facility>>>
  getRawFacilities?: Maybe<Array<Maybe<Facility>>>
  facility?: Maybe<Facility>
  getInstances?: Maybe<Array<Maybe<Instance>>>
  getInstancesByEventId?: Maybe<Array<Maybe<Instance>>>
  getInstancesByDateRange?: Maybe<Array<Maybe<Instance>>>
  getRawInstances?: Maybe<Array<Maybe<Instance>>>
  getRawInstancesByEventRef?: Maybe<Array<Maybe<Instance>>>
  getRawInstanceByRef?: Maybe<Instance>
  instance?: Maybe<Instance>
  getInstanceByRef?: Maybe<Instance>
  getInstancePrices?: Maybe<Array<Maybe<ZonePrice>>>
  getPackages?: Maybe<Array<Maybe<Package>>>
  getPackagesByInternalType?: Maybe<Array<Maybe<Package>>>
  getRawPackage?: Maybe<Package>
  getRawPackages?: Maybe<Array<Maybe<Package>>>
  getPackageByRef?: Maybe<Package>
  package?: Maybe<Package>
  getPriceTypes?: Maybe<Array<Maybe<PriceType>>>
  getRawPriceTypes?: Maybe<Array<Maybe<PriceType>>>
  getProducts?: Maybe<Array<Maybe<Event>>>
  product?: Maybe<Event>
  getRelatedProductsByEventRef?: Maybe<Array<Maybe<Event>>>
  getRelatedProductsByInstanceRef?: Maybe<Array<Maybe<Event>>>
  getRelatedProductsByDate?: Maybe<Array<Maybe<Instance>>>
  getReviews?: Maybe<Array<Maybe<Review>>>
  review?: Maybe<Review>
  getSeatMap?: Maybe<SeatMap>
  getSeatMapByScreen?: Maybe<Screen>
  getSeatAvailability?: Maybe<Array<Maybe<Seat>>>
  getScreenAvailability?: Maybe<Array<Maybe<Screen>>>
  getSeatStatuses?: Maybe<Array<Maybe<SeatStatus>>>
  getSeatTypes?: Maybe<Array<Maybe<SeatType>>>
  getSeatsInBasket?: Maybe<Array<Maybe<Seat>>>
  getScreenZones?: Maybe<Array<Maybe<ScreenZone>>>
  getRawZones?: Maybe<Array<Maybe<Zone>>>
  getRawScreens?: Maybe<Array<Maybe<Screen>>>
  getSeries?: Maybe<Array<Maybe<Series>>>
  getSeriesHierarchical?: Maybe<Array<Maybe<Series>>>
  getSeriesById?: Maybe<Series>
  series?: Maybe<Series>
  getTags?: Maybe<Array<Maybe<Tag>>>
  getTagsByEvent?: Maybe<Array<Maybe<Tag>>>
  getRawTags?: Maybe<Array<Maybe<Tag>>>
  getRawTagsByEvents?: Maybe<Array<Maybe<Tag>>>
  getRawTagsByInstances?: Maybe<Array<Maybe<Tag>>>
  getRawTagsByPackages?: Maybe<Array<Maybe<Tag>>>
  tag?: Maybe<Tag>
  getVenues?: Maybe<Array<Maybe<Venue>>>
  getRawVenues?: Maybe<Array<Maybe<Venue>>>
  venue?: Maybe<Venue>
  getRawZonemaps?: Maybe<Array<Maybe<Zonemap>>>
  getCustomMessages?: Maybe<Array<Maybe<CustomMessage>>>
  getCustomMessagesByPageSlug?: Maybe<Array<Maybe<CustomMessage>>>
  getKeyValue?: Maybe<KeyValue>
}

export type QueryGetBasketFromSessionArgs = {
  session_id?: Maybe<Scalars['String']>
}

export type QueryAdminGetBasketCompleteArgs = {
  session_id: Scalars['String']
}

export type QueryValidateBasketFromSessionArgs = {
  session_id?: Maybe<Scalars['String']>
}

export type QueryGetPaymentMethodsArgs = {
  payment_type_ref?: Maybe<Scalars['OID']>
}

export type QueryGetVerfiedAddressesArgs = {
  postcode: Scalars['String']
  street?: Maybe<Scalars['String']>
}

export type QueryCheckBankAccountDetailsArgs = {
  account_details: DirectDebitAccountDetails
}

export type QueryVerifyBankAccountOwnershipArgs = {
  account_owner: DirectDebitAccountOwnerDetails
}

export type QueryOrderArgs = {
  id: Scalars['OID']
}

export type QueryGetCmsPageArgs = {
  slug: Scalars['String']
}

export type QueryGetCmsMenuArgs = {
  slug: Scalars['String']
}

export type QueryGetCmsArchiveArgs = {
  type: Scalars['String']
  params?: Maybe<CmsArchiveInput>
}

export type QueryGetCmsTaxonomyArgs = {
  type: Scalars['String']
  params?: Maybe<CmsTaxonomyInput>
  archive_params?: Maybe<CmsArchiveParams>
}

export type QueryGetCmsLayoutsByPageArgs = {
  slug: Scalars['String']
}

export type QueryGetCmsOptionsComponentsArgs = {
  components?: Maybe<Scalars['String']>
}

export type QuerySearchCmsArgs = {
  term?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type QueryCustomArgs = {
  name?: Maybe<Scalars['String']>
  filters?: Maybe<Array<Maybe<NameValuePair>>>
}

export type QueryCustomByRefArgs = {
  name?: Maybe<Scalars['String']>
  ref?: Maybe<Scalars['OID']>
}

export type QueryGetListArgs = {
  listInput?: Maybe<CustomListInput>
  search?: Maybe<Scalars['String']>
  filters?: Maybe<Array<Maybe<NameValuePair>>>
}

export type QueryGetReferenceDataArgs = {
  referenceDataInput?: Maybe<ReferenceDataInput>
}

export type QueryCustomerArgs = {
  id: Scalars['OID']
}

export type QueryGetCustomerByRefAndNameArgs = {
  customer_ref: Scalars['OID']
  name?: Maybe<Scalars['String']>
}

export type QueryRefreshTokenArgs = {
  input: RefreshTokenInput
}

export type QuerySendResetTokenArgs = {
  email: Scalars['String']
}

export type QuerySendMagicLoginLinkArgs = {
  email: Scalars['String']
}

export type QueryCheckEmailExistsArgs = {
  email: Scalars['String']
}

export type QueryCheckCustomerHasLoginArgs = {
  customer_ref: Scalars['OID']
}

export type QueryGetStatesArgs = {
  country_ref?: Maybe<Scalars['OID']>
}

export type QueryGetGiftCertificateBalanceArgs = {
  code?: Maybe<Scalars['String']>
}

export type QueryCheckPostcodeArgs = {
  postcode?: Maybe<Scalars['String']>
}

export type QueryGetCustomerListArgs = {
  list_ref?: Maybe<Scalars['OID']>
}

export type QuerySearchCustomersByTypeArgs = {
  type_refs: Scalars['String']
  query: Scalars['String']
  recaptcha: Scalars['String']
}

export type QueryGetSuggestedEventsArgs = {
  types?: Maybe<Scalars['String']>
}

export type QueryPrintTicketsArgs = {
  order_ref?: Maybe<Scalars['OID']>
}

export type QueryGetOrderConfirmationArgs = {
  order_ref?: Maybe<Scalars['OID']>
}

export type QueryFormArgs = {
  id?: Maybe<Scalars['OID']>
}

export type QueryGetFormsByEventTypeArgs = {
  type?: Maybe<Scalars['String']>
}

export type QueryDesignationArgs = {
  id: Scalars['OID']
}

export type QueryGetFundsArgs = {
  allow_unpublished?: Maybe<Scalars['Boolean']>
}

export type QueryFundArgs = {
  id: Scalars['OID']
}

export type QueryGetMembershipsByOrgArgs = {
  membership_organisation_ref: Scalars['OID']
}

export type QueryMembershipArgs = {
  id: Scalars['OID']
}

export type QueryGetMembershipByRefArgs = {
  ref: Scalars['OID']
}

export type QueryGetCustomerMembershipsArgs = {
  includeAffiliates?: Maybe<Scalars['Boolean']>
  current?: Maybe<Scalars['Boolean']>
}

export type QueryGetMembershipsByCustomerRefArgs = {
  customer_ref: Scalars['OID']
  includeAffiliates?: Maybe<Scalars['Boolean']>
  current?: Maybe<Scalars['Boolean']>
}

export type QueryGetDonationsByCustomerRefArgs = {
  customer_ref: Scalars['OID']
  includeAffiliates?: Maybe<Scalars['Boolean']>
  current?: Maybe<Scalars['Boolean']>
}

export type QueryGetNoteByIdArgs = {
  id: Scalars['OID']
}

export type QueryGetNoteBySlugArgs = {
  slug: Scalars['String']
}

export type QueryGetArtistsByRoleArgs = {
  role_id: Scalars['OID']
}

export type QueryArtistArgs = {
  id: Scalars['OID']
}

export type QueryCalendarMonthArgs = {
  input?: Maybe<CalendarInput>
}

export type QueryGetEventsArgs = {
  type?: Maybe<Scalars['String']>
  range?: Maybe<DateRangeInput>
}

export type QueryGetAllEventsArgs = {
  type?: Maybe<Scalars['String']>
}

export type QueryGetArchiveEventsArgs = {
  type?: Maybe<Scalars['String']>
}

export type QueryEventArgs = {
  id: Scalars['OID']
  preview?: Maybe<Scalars['Boolean']>
}

export type QueryGetEventByRefArgs = {
  id: Scalars['OID']
  preview?: Maybe<Scalars['Boolean']>
}

export type QueryGetEventByAliasArgs = {
  alias: Scalars['String']
}

export type QueryGetEventBySlugArgs = {
  slug: Scalars['String']
}

export type QueryGetRawEventArgs = {
  id: Scalars['OID']
}

export type QueryFacilityArgs = {
  id: Scalars['OID']
}

export type QueryGetInstancesByEventIdArgs = {
  event_id?: Maybe<Scalars['OID']>
  range?: Maybe<DateRangeInput>
}

export type QueryGetInstancesByDateRangeArgs = {
  range?: Maybe<DateRangeInput>
  brief?: Maybe<Scalars['Boolean']>
}

export type QueryGetRawInstancesByEventRefArgs = {
  event_ref?: Maybe<Scalars['OID']>
}

export type QueryGetRawInstanceByRefArgs = {
  instance_ref?: Maybe<Scalars['OID']>
}

export type QueryInstanceArgs = {
  id: Scalars['OID']
}

export type QueryGetInstanceByRefArgs = {
  instance_ref?: Maybe<Scalars['OID']>
}

export type QueryGetInstancePricesArgs = {
  instance_ref: Scalars['OID']
}

export type QueryGetPackagesArgs = {
  type?: Maybe<Scalars['String']>
  range?: Maybe<DateRangeInput>
}

export type QueryGetPackagesByInternalTypeArgs = {
  internal_type: Scalars['String']
  range?: Maybe<DateRangeInput>
}

export type QueryGetRawPackageArgs = {
  package_ref?: Maybe<Scalars['OID']>
}

export type QueryGetPackageByRefArgs = {
  package_ref: Scalars['OID']
}

export type QueryPackageArgs = {
  id: Scalars['OID']
}

export type QueryProductArgs = {
  id: Scalars['OID']
  preview?: Maybe<Scalars['Boolean']>
}

export type QueryGetRelatedProductsByEventRefArgs = {
  event_ref: Scalars['OID']
}

export type QueryGetRelatedProductsByInstanceRefArgs = {
  instance_ref: Scalars['OID']
}

export type QueryGetRelatedProductsByDateArgs = {
  date: Scalars['Date']
}

export type QueryReviewArgs = {
  id: Scalars['OID']
}

export type QueryGetSeatMapArgs = {
  instance_ref: Scalars['OID']
}

export type QueryGetSeatMapByScreenArgs = {
  instance_ref: Scalars['OID']
  screen_ref: Scalars['OID']
}

export type QueryGetSeatAvailabilityArgs = {
  getSeatsInput?: Maybe<GetSeatsInput>
}

export type QueryGetScreenAvailabilityArgs = {
  instance_ref: Scalars['OID']
}

export type QueryGetScreenZonesArgs = {
  instance_ref: Scalars['OID']
  facility_ref?: Maybe<Scalars['OID']>
}

export type QueryGetRawZonesArgs = {
  zonemap_ref?: Maybe<Scalars['OID']>
}

export type QueryGetRawScreensArgs = {
  facility_ref: Scalars['OID']
  instance_ref: Scalars['OID']
}

export type QueryGetSeriesByIdArgs = {
  id: Scalars['Int']
}

export type QuerySeriesArgs = {
  alias: Scalars['String']
}

export type QueryGetTagsByEventArgs = {
  event_id: Scalars['OID']
}

export type QueryGetRawTagsByEventsArgs = {
  event_refs: Array<Maybe<Scalars['OID']>>
}

export type QueryGetRawTagsByInstancesArgs = {
  instance_refs: Array<Maybe<Scalars['OID']>>
}

export type QueryGetRawTagsByPackagesArgs = {
  package_refs: Array<Maybe<Scalars['OID']>>
}

export type QueryTagArgs = {
  tag: Scalars['String']
}

export type QueryVenueArgs = {
  id: Scalars['OID']
}

export type QueryGetRawZonemapsArgs = {
  facility_ref: Scalars['OID']
}

export type QueryGetCustomMessagesByPageSlugArgs = {
  slug: Scalars['String']
}

export type QueryGetKeyValueArgs = {
  key?: Maybe<Scalars['String']>
}

export type ReferenceDataInput = {
  table: Scalars['String']
  summary?: Maybe<Scalars['Boolean']>
}

export type RefreshTokenInput = {
  token_refresh?: Maybe<Scalars['String']>
}

export type Relationship = {
  relationship_ref?: Maybe<Scalars['OID']>
  customer_ref?: Maybe<Scalars['OID']>
  associated_customer_ref?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  context?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['Date']>
  active?: Maybe<Scalars['Boolean']>
  primary?: Maybe<Scalars['Boolean']>
  name_indicator?: Maybe<Scalars['Int']>
}

export type RemoveAffiliationInput = {
  affiliation_ref?: Maybe<Scalars['OID']>
}

export type ResearchType = {
  research_type_ref?: Maybe<Scalars['OID']>
  description?: Maybe<Scalars['String']>
}

export type Review = {
  id?: Maybe<Scalars['OID']>
  event_id?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['Date']>
  source?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  quote?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  stars?: Maybe<Scalars['Int']>
  event?: Maybe<Event>
}

export type RsvpInput = {
  instance_ref?: Maybe<Scalars['OID']>
  price_type_ref?: Maybe<Scalars['OID']>
  tickets?: Maybe<Scalars['Int']>
}

export type Screen = {
  screen_ref: Scalars['OID']
  title?: Maybe<Scalars['String']>
  rows?: Maybe<Scalars['Int']>
  columns?: Maybe<Scalars['Int']>
  availability?: Maybe<Scalars['Int']>
  price_range?: Maybe<Scalars['JSON']>
  rectangles?: Maybe<Array<Maybe<Scalars['JSON']>>>
  seats?: Maybe<Array<Maybe<Seat>>>
  seatmap_image?: Maybe<Scalars['String']>
  seating_configuration?: Maybe<Scalars['String']>
}

export type ScreenZone = {
  screen_title?: Maybe<Scalars['String']>
  screen_ref?: Maybe<Scalars['OID']>
  section_ref?: Maybe<Scalars['OID']>
  availability?: Maybe<Scalars['Int']>
  wheelchair_availability?: Maybe<Scalars['Int']>
  zones?: Maybe<Array<Maybe<Zone>>>
  price_range?: Maybe<Scalars['JSON']>
}

export type Seat = {
  seat_ref?: Maybe<Scalars['OID']>
  zone_ref?: Maybe<Scalars['OID']>
  screen_ref?: Maybe<Scalars['OID']>
  type_ref?: Maybe<Scalars['OID']>
  status_ref?: Maybe<Scalars['OID']>
  allocation_ref?: Maybe<Scalars['OID']>
  available?: Maybe<Scalars['Boolean']>
  type?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  row?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  x?: Maybe<Scalars['Int']>
  y?: Maybe<Scalars['Int']>
  seat?: Maybe<Scalars['Boolean']>
  aisle?: Maybe<Scalars['Boolean']>
  stairs?: Maybe<Scalars['Boolean']>
  letter?: Maybe<Scalars['String']>
  hold_code?: Maybe<HoldCode>
}

export type SeatMap = {
  instance_ref: Scalars['OID']
  seatmap_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  screens?: Maybe<Array<Maybe<Screen>>>
  zones?: Maybe<Array<Maybe<Zone>>>
  seatmap_image?: Maybe<Scalars['String']>
}

export type SeatStatus = {
  status_ref?: Maybe<Scalars['OID']>
  status?: Maybe<Scalars['String']>
}

export type SeatType = {
  type_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
}

export type Series = {
  id?: Maybe<Scalars['Int']>
  parent_id?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
  alias?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  sponsors?: Maybe<Scalars['JSON']>
  extra?: Maybe<Scalars['JSON']>
  published?: Maybe<Scalars['Boolean']>
  children?: Maybe<Array<Maybe<Series>>>
  events?: Maybe<Array<Maybe<Event>>>
  instances?: Maybe<Array<Maybe<Instance>>>
}

export type SeriesSummary = {
  id?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
  alias?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type SessionToken = {
  session_id?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['JSON']>
}

export type SocialLoginInput = {
  id_token: Scalars['String']
  provider: Scalars['String']
  nonce: Scalars['String']
}

export type State = {
  state_ref?: Maybe<Scalars['OID']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  country_ref?: Maybe<Scalars['OID']>
  country?: Maybe<Scalars['String']>
}

export type SubOrderItem = {
  item_ref?: Maybe<Scalars['OID']>
  sub_item_ref?: Maybe<Scalars['OID']>
  unit_price?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  price_type?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type Subscription = {
  designationCreated?: Maybe<Designation>
  artistCreated?: Maybe<Artist>
  eventCreated?: Maybe<Event>
}

export type Suffix = {
  suffix_ref?: Maybe<Scalars['OID']>
  name?: Maybe<Scalars['String']>
}

export type Tag = {
  id?: Maybe<Scalars['OID']>
  tag_ref?: Maybe<Scalars['OID']>
  element_ref?: Maybe<Scalars['OID']>
  tag?: Maybe<Scalars['String']>
  groups?: Maybe<Array<Maybe<Scalars['String']>>>
  element_type?: Maybe<Scalars['String']>
  published?: Maybe<Scalars['Boolean']>
  events?: Maybe<Array<Maybe<Event>>>
  instances?: Maybe<Array<Maybe<Instance>>>
  packages?: Maybe<Array<Maybe<Package>>>
}

export type Ticket = {
  order_ref?: Maybe<Scalars['OID']>
  instance_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['Date']>
  total?: Maybe<Scalars['String']>
  details?: Maybe<Scalars['JSON']>
  event?: Maybe<Event>
}

export type TokenSet = {
  token?: Maybe<Scalars['String']>
  logged_in?: Maybe<Scalars['Boolean']>
  session_id?: Maybe<Scalars['String']>
}

export type UpdateCustomerServiceActionInput = {
  action_ref: Scalars['OID']
  action: CreateCustomerServiceActionInput
}

export type UpdateCustomerServiceIssueInput = {
  issue_ref: Scalars['OID']
  issue: CreateCustomerServiceIssueInput
}

export type UpdateLoginInput = {
  email?: Maybe<Scalars['String']>
  password?: Maybe<UpdatePasswordInput>
}

export type UpdatePasswordInput = {
  password: Scalars['String']
  existing?: Maybe<Scalars['String']>
}

export type Venue = {
  id?: Maybe<Scalars['OID']>
  venue_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  alias?: Maybe<Scalars['String']>
  address1?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  postcode?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  opening_hours?: Maybe<Scalars['String']>
  opening_times?: Maybe<Scalars['JSON']>
  website?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  public_transport?: Maybe<Scalars['String']>
  parking?: Maybe<Scalars['String']>
  accessibility?: Maybe<Scalars['String']>
  concessions?: Maybe<Scalars['String']>
  policies?: Maybe<Scalars['String']>
  pre_event_info?: Maybe<Scalars['String']>
  post_event_info?: Maybe<Scalars['String']>
  images?: Maybe<Scalars['JSON']>
  attachments?: Maybe<Scalars['JSON']>
  extra?: Maybe<Scalars['JSON']>
  parking_options?: Maybe<Scalars['JSON']>
  lat?: Maybe<Scalars['Float']>
  lng?: Maybe<Scalars['Float']>
  directions?: Maybe<Scalars['String']>
  seats?: Maybe<Scalars['Int']>
  published?: Maybe<Scalars['Boolean']>
  featured?: Maybe<Scalars['Boolean']>
  instances?: Maybe<Array<Maybe<Instance>>>
  events?: Maybe<Array<Maybe<Event>>>
}

export type WebContent = {
  parent_ref?: Maybe<Scalars['OID']>
  content_ref?: Maybe<Scalars['OID']>
  type?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
}

export type Zone = {
  zone_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  availability?: Maybe<Scalars['Int']>
  instance_ref?: Maybe<Scalars['OID']>
  prices?: Maybe<Array<Maybe<ZonePrice>>>
  base_price?: Maybe<ZonePrice>
  valid_prices?: Maybe<Array<Maybe<ZonePrice>>>
  seat_range?: Maybe<Scalars['JSON']>
  zonemap_ref?: Maybe<Scalars['OID']>
}

export type Zonemap = {
  id?: Maybe<Scalars['OID']>
  zonemap_ref?: Maybe<Scalars['OID']>
  title?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['JSON']>
}

export type ZonePrice = {
  zones?: Maybe<Array<Maybe<Zone>>>
  zone_ref?: Maybe<Scalars['OID']>
  instance_ref?: Maybe<Scalars['OID']>
  price_type_ref?: Maybe<Scalars['OID']>
  zone_title?: Maybe<Scalars['String']>
  price_title?: Maybe<Scalars['String']>
  price_description?: Maybe<Scalars['String']>
  price_information?: Maybe<Scalars['String']>
  price_image?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  enabled?: Maybe<Scalars['Boolean']>
  base?: Maybe<Scalars['Boolean']>
  best?: Maybe<Scalars['Boolean']>
  offer?: Maybe<Scalars['Boolean']>
  availability?: Maybe<Scalars['Int']>
}
