




import { Component, Prop, Vue } from 'nuxt-property-decorator'
@Component
export default class Error extends Vue {
  @Prop({ default: 500 }) type?: string | number
  @Prop({ default: '/' }) link!: string

  public templates = {
    401: `<h1>401</h1>
    <h2><span>Error: 401</span> You are not authorised to view this page</h2>`,
    404: `<h1>404</h1>
    <h2><span>Error 404:</span> Page cannot be found</h2>
    <p>Sorry, we can't find the page you’re looking for.</p>
    <p class="secondary-info">
      Why not take a look at our what’s on page?
    </p>
    <nuxt-link
      to="${this.$config ? this.$config.get('URLS').whats_on : ''}"
      class="button button--primary"
      >What's on</nuxt-link>`,
    500: `<h1>Error</h1>
    <h3>We're sorry, there was an error with our ticketing system</h3>
    <p>Please wait a moment and try again</p>
    <p class="secondary-info">
      This has been logged and our technical team are investigating.
    </p>
    `,
    basket_empty: `<h1 class="basket-empty__header">Basket Empty</h1>
    <h2>Your basket is currently empty</h2>
    <p class="weight-normal">This may be because the timer has run out on the tickets you selected</p>
    <p class="weight-normal">
    Please try again
    </p>
    <nuxt-link
      to="${this.$config ? this.$config.get('URLS').whats_on : ''}"
      class="button button--primary"
      >What's on</nuxt-link>`,
    seat_map_failed: `<h1 class="basket-empty__header">Error</h1>
    <h2>We're sorry, the seat map could not be loaded for this performance</h2>
    <p class="weight-normal">We are working on resolving this issue</p>
    <p class="weight-normal">
    Please try selecting the best seats, or contact us if the issue persists
    </p>
    <nuxt-link
      to="/basket"
      class="button button--primary"

      >Select best seats</nuxt-link
    >`,
    off_sale: `<h1 class="basket-empty__header">Event not on sale</h1>
    <p>This event is not currently on sale. If you have been sent a presale link, please access the link directly.</p>
    <nuxt-link
      to="${this.$config ? this.$config.get('URLS').whats_on : ''}"
      class="button button--primary"
      >What's on</nuxt-link>`,
    pre_sale: `<h1 class="basket-empty__header">Event Pre-sale</h1>
    <p>You currently do not have access to purchase tickets for this event</p>
    <p>If you are a member, please ensure that you are <nuxt-link
        to="/sign-in"
        >logged in to your account</nuxt-link>, and that your membership qualifies for early access.</p>
    <p>If you have been sent a promotional link, please use that link directly to gain access.</p>
    <div class="button-wrap">
      <nuxt-link
        to="/sign-in"
        class="button button--primary"
        >Sign in</nuxt-link
      >
      <nuxt-link
        to="${this.$config ? this.$config.get('URLS').whats_on : ''}"
        class="button button--secondary"
        >What's on</nuxt-link
      >
    </div>
    <p>You can find the Booking Dates and times for this event on the show's <nuxt-link to="${
      this.link
    }">webpage</nuxt-link></p>`,
  }

  render(h) {
    const innerHTML = this.getTemplate(this.type)
    return h({
      props: {
        link: this.link,
      },
      template: `<div class="error-component">${innerHTML}</div>`,
    })
  }

  getTemplate(type) {
    return this.templates[type] ? this.templates[type] : this.templates[500]
  }

  mounted() {
    const body = document.body

    if (body) {
      body.classList.add(`error--${this.type}`)
    }
  }

  beforeDestroy() {
    const body = document.body

    if (body) {
      body.classList.remove(`error--${this.type}`)
    }
  }
}
