/**
 * Builds an array of filter conditions
 *
 * @param { string[] } - an array of strings, start a string with ! to use negation
 * @param { stribg } - the object key to filter on
 */
const buildDynamicQuery = (conditions: string[], key: string) => {
  const filterFunctions: ((item: any) => boolean)[] = []

  for (const f of conditions) {
    filterFunctions.push((item: any) =>
      f.indexOf('!') === 0
        ? item &&
          item[key] &&
          !item[key].toLowerCase().includes(f.replace('!', ''))
        : item && item[key] && item[key].toLowerCase().includes(f)
    )
  }

  return filterFunctions
}

export { buildDynamicQuery }
