import actions from '@Core/store/session/actions'
import state from '@Core/store/session/state'
import getters from '@Core/store/session/getters'
import mutations from '@Core/store/session/mutations'

export const name = 'session'

export default {
  namespaced: true,
  name,
  state,
  actions,
  getters,
  mutations,
}
