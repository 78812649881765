import { CmsImage } from '../@types/skyway'

type EventImage = {
  [key: string]: string
}

/**
 *
 * @param {EventImage} imageData
 * @param {string} alt
 * @returns {CmsImage}
 */
export const formatImage = (imageData: EventImage, alt?: string): CmsImage => {
  if (imageData) {
    const imageArr = Object.entries(imageData).map(([name, url]) => ({
      name,
      url,
    }))

    const imgObject = {
      alt,
      sizes: imageArr,
    }

    return imgObject
  } else {
    return { alt }
  }
}
