import { createDecorator } from 'vue-class-component'
import _throttle from 'lodash/throttle'
import _debounce from 'lodash/debounce'

export const Debounce = (waitMs: number) =>
  createDecorator((options, key) => {
    if (options.methods && options.methods[key]) {
      const originalMethod = options.methods[key]
      const debounceMethod = _debounce(originalMethod, waitMs, {
        leading: false,
        trailing: true,
      })

      options.methods[key] = async function (...args: any) {
        await debounceMethod.apply(this, args)
      }
    }
  })

export const Throttle = (waitMs: number) =>
  createDecorator((options, key) => {
    if (options.methods && options.methods[key]) {
      const originalMethod = options.methods[key]
      const throttleMethod = _throttle(originalMethod, waitMs, {
        leading: true,
        trailing: false,
      })

      options.methods[key] = async function (...args: any) {
        await throttleMethod.apply(this, args)
      }
    }
  })
