







































































import { Component, Vue, namespace, Prop } from 'nuxt-property-decorator'
import SocialLinks from '@UI/components/social-links/SocialLinks.vue'
import NewsletterSignup from '@Marketing/components/parts/newsletter/NewsletterSignup.vue'
import { CmsComponent } from '@Core/@types/skyway'
import RedLogo from '~/static/images/logos/RedLogo.svg?inline'
import WhiteLogo from '~/static/images/logos/WhiteLogo.svg?inline'
import ACELogo from '~/static/images/logos/affiliate/ace.svg?inline'
import BloombergLogo from '~/static/images/logos/affiliate/bloomberg.svg?inline'

// import HereForCultureLogo from '~/static/images/logos/affiliate/hereforculture.svg?inline'
import PHFLogo from '~/static/images/logos/affiliate/phf-logo.svg?inline'

import ADLogo from '~/static/images/logos/affiliate/afterdigital.svg?inline'
import SkywayLogo from '~/static/images/logos/affiliate/skyway.svg?inline'

const options = namespace('options')

@Component({
  components: {
    RedLogo,
    WhiteLogo,
    ACELogo,
    BloombergLogo,
    PHFLogo,
    ADLogo,
    SkywayLogo,
    SocialLinks,
    NewsletterSignup,
  },
})
export default class Footer extends Vue {
  @Prop({ type: Boolean, default: false }) isReduced!: boolean

  @options.Action
  protected getCMSOptionsComponents!: () => Promise<CmsComponent[]>

  @options.Getter
  protected allOptionComponentsByName!: (name: string) => CmsComponent[]

  get socialLinksComponents() {
    return this.allOptionComponentsByName('SocialLinkCollection')
  }

  /**
   * Needs to change for venue hire
   * not ideal having to use the deep nested
   * extra.type to filter here
   */
  get socialData() {
    const comp = this.isVenueHire
      ? this.socialLinksComponents &&
        this.socialLinksComponents.find((c) => c && c.data.extra)
      : this.socialLinksComponents &&
        this.socialLinksComponents.find(
          (c) => (c && !c.data.extra) || !c.data.extra.type
        )

    return (comp && comp.data) || false
  }

  async fetch() {
    if (!this.socialLinksComponents) {
      await this.getCMSOptionsComponents()
    }
  }

  get isVenueHire(): Boolean {
    return this.$route.path.includes('venue-hire')
  }
}
