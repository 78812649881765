


































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { CmsMenuItem } from '@Core/@types/skyway'

@Component
export default class MenuDesktop extends Vue {
  @Prop({ type: Array, required: true }) items!: CmsMenuItem[]
  @Prop({ type: Boolean, default: false }) isOpen!: Boolean

  protected activeItem: CmsMenuItem | null = null

  menuIsActive(item: string): boolean {
    if (this.activeItem) {
      return this.activeItem.name === item.name
    } else {
      return false
    }
  }

  setActiveItem(item: CmsMenuItem | null): void {
    this.activeItem = item
  }
}
