

































import { Component, mixins, namespace } from 'nuxt-property-decorator'
import HeaderPart from '@Core/components/parts/header/HeaderPart.vue'
import FooterPart from '@Core/components/parts/footer/FooterPart.vue'
import ResizeObserver from '@UI/components/resize-oberserver/ResizeObserver.vue'
import { Route } from 'vue-router'
import { CmsComponent, CmsPage } from '@Core/@types/skyway'
import PromoCodeHandler from '@Core/mixins/PromoCodeHandler.vue'

const page = namespace('page')

@Component({
  components: {
    HeaderPart,
    FooterPart,
    ResizeObserver,
  },
})
export default class LayoutDefault extends mixins<PromoCodeHandler>(
  PromoCodeHandler
) {
  protected hasMounted: boolean = false
  protected increment: number = 0
  protected updatingPromo: boolean = false

  head() {
    return {
      titleTemplate: '%s | Roundhouse',
    }
  }

  /**
   * Use purchase path routes to scope styles to layout--purchase-path
   */
  private purchasePathRoutes: string[] = [
    'sign-in',
    'register',
    'account',
    'calendar-id',
    'basket',
    'delivery',
    'cart',
    'payment',
    'success',
    'cancelled',
    'confirmation',
    'select-area',
    'select-seats',
    'general-admission',
    'best-available',
    'best-available-step-1',
    'best-available-step-2',
    'best-available-step-3',
    'gift-membership',
    'gift-membership-level',
    'redeem-gift-membership',
    'gift-voucher',
    'membership',
    'book-a-studio',
    'book-a-studio-id',
    'book-a-stuio-enquiry',
    'classes',
    'dob',
    'details',
    'apply',
    'workshop-id',
    'workshop-id-form-id',
    'creative-studios-membership',
    'requests',
    'donations',
  ]

  get isPurchasePath() {
    return typeof this.$route.name === 'string'
      ? this.purchasePathRoutes.includes(this.$route.name)
      : false
  }

  get isSeatmap() {
    return Boolean(this.$route.name === 'select-seats')
  }

  get hideFooter() {
    return Boolean(this.$route.name === 'select-seats')
  }

  get breadcrumbs(): { title: string; href: Route }[] {
    if (process.server) {
      return []
    }
    const fullPath = this.$route.path.replace(/\/$/, '')
    const params = fullPath.startsWith('/')
      ? fullPath.substring(1).split('/')
      : fullPath.split('/')
    const crumbs: { title: string; href: Route }[] = []
    let path = ''
    params.forEach((param, index) => {
      path = `${path}/${param}`
      const match = this.$router.match(path)
      if (match.name !== null) {
        if (
          this.$route.name === 'series-landing-id' &&
          match.path === '/series'
        ) {
          const href = {
            ...match,
            path: match.path.replace('series', 'whats-on'),
            fullPath: match.path.replace('series', 'whats-on'),
          }
          crumbs.push({
            title: 'series',
            href,
          })
        } else {
          const title =
            index === params.length - 1
              ? (this.page && this.page.title) || param.replaceAll('-', ' ')
              : param.replaceAll('-', ' ')
          crumbs.push({
            title,
            href: match,
          })
        }
      }
    })
    return crumbs || []
  }

  @page.Getter
  public allComponentsByName!: (name: string) => CmsComponent[]

  @page.State
  public page?: CmsPage

  get hasBanner() {
    const banners = this.allComponentsByName('Banner')

    return banners.length > 0
  }

  get hasInPageNav() {
    const inPageNav = this.allComponentsByName('AnchorLink')

    return inPageNav.length > 0
  }
}
