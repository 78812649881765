import { config } from '@Core/config/skyway'

const parser = (operationName) => {
  const lstorage = window.localStorage.getItem('crowdhandler')
  const lstorage_parsed = lstorage ? JSON.parse(lstorage) : null
  const lsToken = lstorage_parsed.token[config.CROWDHANDLER_DOMAIN] || ''

  const url = encodeURI(window.location.href)
  const token = encodeURI(lsToken)

  return `?operationName=${operationName}&chToken=${token}&sourceURL=${url}`
}

export default parser
