export interface State {
  redirect?: string
  autoDonationApplied?: boolean
  autoDonationRefused?: boolean
  autoDonationBasketId?: number | null
  seatMapInstructionsDismissed?: boolean
  cookiePreferences?: string[] | [] | null
  cookieConsent?: boolean | null
}

/**
 * Initial state, empty array of events
 */
const state = (): State => ({
  redirect: '/account',
  autoDonationApplied: false,
  autoDonationRefused: false,
  autoDonationBasketId: null,
  seatMapInstructionsDismissed: false,
  cookiePreferences: null,
  cookieConsent: null,
})

export default state
