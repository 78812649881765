



import { Component, Vue, namespace } from 'nuxt-property-decorator'
import { BasketProperties } from '@Core/@types/skyway'

/**
 * Hardcoded as workaround for now when applying promo
 * via url [https://alienation.atlassian.net/browse/RS-59]
 */
const PROMO_MOS = '33'
const basket = namespace('basket')

@Component
export default class PromoCodeHandler extends Vue {
  @basket.Action
  protected applyPromoCode!: (code: string) => Promise<boolean>

  @basket.Action
  protected transferBasket!: () => Promise<void>

  @basket.Action
  protected getBasketProperties!: () => Promise<BasketProperties>

  @basket.Action
  protected setModeOfSale!: (mos: number) => Promise<any>

  @basket.State
  protected properties!: BasketProperties

  /**
   * Promo code is persisted in a cookie for 30 mins
   * so will be available after page refresh
   */
  @basket.State
  protected promo_code!: string

  protected hasMounted: boolean = false
  protected increment: number = 0
  protected updatingPromo: boolean = false

  handleRefreshOnPromo() {
    const url = new URL(window.location.href)
    if (url.searchParams.has('promo')) {
      url.searchParams.delete('promo')
      url.searchParams.set('applied', 'true')
    }
    window.location.href = url.toString()
  }

  async checkBasketProperties() {
    await this.getBasketProperties()
    if (
      this.promo_code &&
      this.properties &&
      this.properties.mode_of_sale_ref !== PROMO_MOS
    ) {
      return false
    } else {
      return true
    }
  }

  async handlePromoCode(retry = 0) {
    const { query } = this.$route

    if (query && query.promo && typeof query.promo === 'string') {
      if (retry >= 4) {
        throw new Error('Promo code retries exceeded')
      }

      try {
        const applied = await this.applyPromoCode(query.promo)

        if (applied) {
          this.updatingPromo = true

          setTimeout(async () => {
            /**
             * Check basket properties will return true if the MOS
             * has been updated, false otherwise
             */
            const result = await this.checkBasketProperties()

            if (!result) {
              /**
               * If the MOS switch fails, increment retry
               * transfer basket and try again. Exponentially
               * increase retry time
               */
              await this.transferBasket()
              await this.handlePromoCode(retry + 1)
            } else {
              /**
               * If the switch is successful, reload the page
               * This ensure we get all prices and availability
               * with the new MOS
               */
              this.handleRefreshOnPromo()
            }
          }, 500 * Math.pow(2, retry))
        }
      } catch (error) {
        this.updatingPromo = false
        /**
         * Tag specific errors in Sentry so we can track them down
         * more easily
         */
        this.$logger.captureException(error, {
          tags: {
            promoCode: 'Failed applying promo code',
          },
        })
      }
    } else if (query && query.applied) {
      setTimeout(() => {
        this.$eventBus.notifySuccess('Promo code applied!')
      }, 1000)
    }
  }

  async mounted() {
    await this.handlePromoCode()
    this.hasMounted = true
  }
}
