

































import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
import AccountCircleSvg from '@icons/material/svg/account-circle.svg?inline'
import { CmsMenuItem } from '@Core/@types/skyway'

@Component({
  components: {
    AccountCircleSvg,
  },
})
export default class MobileMenuMain extends Vue {
  @Prop({ type: Array, required: true }) items!: CmsMenuItem[]

  @Emit('openMenu')
  openMenu(item: CmsMenuItem) {
    if (item.children && item.children.length) {
      return item.name?.toLowerCase()
    } else {
      return item
    }
  }
}
