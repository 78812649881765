function updateOrAddEntitiesForArray<T>(
  entities: T[],
  payload: T[],
  compareFn: (a: T, b: T) => boolean
): void {
  for (const payloadEntity of payload) {
    const index = entities.findIndex((entity) =>
      compareFn(entity, payloadEntity)
    )

    if (index !== -1) {
      entities[index] = payloadEntity
    } else {
      entities.push(payloadEntity)
    }
  }
}

function updateOrAddEntitiesForObject<T>(
  entities: { [key: string]: T[] },
  payload: T[],
  keyPropertyName: string,
  compareFn: (a: T, b: T) => boolean
): void {
  for (const payloadEntity of payload) {
    const key = Object.keys(entities).find((k) =>
      entities[k].some((entity) => compareFn(entity, payloadEntity))
    )

    if (key) {
      entities[key] = entities[key].map((entity) =>
        compareFn(entity, payloadEntity) ? payloadEntity : entity
      )
    } else {
      const newKey = payloadEntity[keyPropertyName]
      entities[newKey] = [payloadEntity]
    }
  }
}

export { updateOrAddEntitiesForArray, updateOrAddEntitiesForObject }
