




















import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Hamburger extends Vue {
  @Prop({ type: Boolean, default: false }) menuIsOpen!: boolean
  @Prop({ type: Boolean, default: false }) hideLabel!: boolean

  @Emit('toggleMenu')
  toggleMenu(): string {
    return 'toggleMenu'
  }
}
